import { Injectable, ErrorHandler } from '@angular/core';
import { LoadingControllerHandler } from './loading-controller-handler';
import { AnalyticsProvider } from './analytics/analytics';

@Injectable({ providedIn: 'root' })
export class MTAErrorHandler implements ErrorHandler {
  constructor(private loadingControllerHandler: LoadingControllerHandler, private analytics: AnalyticsProvider) { }

  handleError(error: any) {
    if (this.loadingControllerHandler.loadingController) {
      this.loadingControllerHandler.dismissAllLoaders();
    }
    if (!this.loadingControllerHandler.alertController) {
      this.loadingControllerHandler.presentAlert(error.message);
    }

    const internalError = {
      subject: 'TO Notes Logs',
      body: error.message,
      date: new Date().toString(),
      errorStack: error.stack
    };
    this.analytics.error('general-error', 'Internal exception', internalError);

  }
}
