import { AppBaseEntityD } from "../AppBaseEntityD";

export interface IOBS {
    Name: string;
    InstanceId: number;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class OBSDexie extends AppBaseEntityD implements IOBS {
    constructor(obsObject?: object) {
        super();
        for (const key in obsObject) {
          if (obsObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = obsObject[key];
          }
        }
      }
    Name: string;
    InstanceId: number;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}