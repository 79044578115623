import { Downloadable, Uploadable } from '../../interface/SyncInterface';
import { BaseEntity, QueryRunner } from 'typeorm/browser';
import { Constants } from 'src/shared/constants';
import { AppBaseEntityD } from '../entity/AppBaseEntityD';
import { db } from 'src/services/dexie.service';
import { UserDexie } from '../entity/dexie/User.dexie';

export class AppBaseEntityManager {
  protected static _instance: AppBaseEntityManager;
  protected _entityType = AppBaseEntityD;

  static get Instance(): AppBaseEntityManager {
    return this._instance || (this._instance = new this());
  }

  isDownloadable(): this is Downloadable {
    return 'download' in this;
  }

  isUploadable(): this is Uploadable {
    return 'upload' in this;
  }

  description(): string {
    return `${this._entityType.name}`;
  }

  async getAll(): Promise<UserDexie[]> {
    const user = await db.user.toArray();
    return user as UserDexie[];
  }

  async bulkInsert2(
    data: AppBaseEntityD[] | any,
    dexieName?: string
  ): Promise<void> {
    try {
      let sql = '';
      if (data instanceof Array) {
        const entityArray: any[] = [];
        for (const d of data) {
          const e = new this._entityType(d);
          Object.keys(e).forEach((key) =>
            e[key] === undefined ? delete e[key] : {}
          );
          entityArray.push(e);
        }
        const entityLength = entityArray.length;
        if (entityLength > 0) {
          let entityCountLeft = entityLength;
          do {
            const entityCountToTake = Math.min(
              entityCountLeft,
              Constants.BulkSize
            );
            const sub = entityArray.splice(0, entityCountToTake);
            db.table(dexieName)
              .bulkPut(sub)
              .then((lastKey) => {
                console.log('CHEV::bulkInsertWithQueryRunner:bulkAdd', {
                  lastKey,
                });
              })
              .catch((bulkError) => {
                console.log('CHEV::bulkInsertWithQueryRunner:bulkError', {
                  bulkError,
                });
              });
            entityCountLeft -= entityCountToTake;
          } while (entityCountLeft > 0);
        }
      } else {
        const e = new this._entityType();
        await db.table(dexieName).put(e);
      }
      return;
    } catch (e) {
      throw 'Cannot insert data to database.' + e;
    }
  }

  async bulkInsertWithQueryRunner(
    data: AppBaseEntityD[] | any,
    queryRunner: QueryRunner
  ): Promise<void> {
    try {
      let sql = '';
      if (data instanceof Array) {
        const entityArray: any[] = [];
        for (const d of data) {
          const e = new this._entityType(d);
          entityArray.push(e);
        }
        if (entityArray.length > 0) {
          sql = entityArray[0].getInsertSqlForEntities(entityArray);
          await queryRunner.query(sql);
        }
      } else {
        const e = new this._entityType();
        sql = e.getInsertSqlForEntity();
        await queryRunner.query(sql);
      }
      return;
    } catch (e) {
      throw e;
    }
  }
}
