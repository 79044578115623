// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inverted-rounded-button {
  border: 1px solid var(--ion-color-primary);
  border-radius: 1em;
  color: var(--ion-color-light);
  font-family: "Gotham-Book";
  font-size: 1em;
  background: var(--ion-color-primary);
  padding: 0.3em 0.8em;
  margin: 1em 0em;
}

.no-turnover-notes {
  color: #6b6d6f;
  font-family: "Gotham-Bold";
  font-size: 1.1em;
}

.add-to {
  font-family: "Gotham-Bold, Arial, Verdana, Tahoma, sans-serif";
}`, "",{"version":3,"sources":["webpack://./src/components/no-notes/no-notes.component.scss","webpack://./src/theme/mixins/app.mixins.scss"],"names":[],"mappings":"AAEA;ECUC,0CAAA;EACA,kBAAA;EACA,6BAAA;EACA,0BAAA;EACA,cAAA;EACA,oCAAA;EACA,oBAAA;EACA,eAAA;ADVD;;AAHA;EACE,cAAA;EACA,0BAAA;EACA,gBAAA;AAMF;;AAFA;EACE,8DAAA;AAKF","sourcesContent":["@import \"../../theme/mixins/app.mixins.scss\";\n\n.inverted-rounded-button {\n  @include inverted-rounded-button;\n}\n\n.no-turnover-notes {\n  color: #6b6d6f;\n  font-family: \"Gotham-Bold\";\n  font-size: 1.1em;\n}\n\n\n.add-to {\n  font-family: \"Gotham-Bold, Arial, Verdana, Tahoma, sans-serif\";\n}","@mixin rounded-button {\n\tborder: 1px solid var(--ion-color-primary);\n\tborder-radius: 1em;\n\tcolor: var(--ion-color-primary);\n\tfont-family: \"Gotham-Book\";\n\tfont-size: 1em;\n\tbackground: transparent;\n\tpadding: 0.3em 0.8em;\n\tmargin: 1em 0em;\n}\n\n@mixin inverted-rounded-button {\n\tborder: 1px solid var(--ion-color-primary);\n\tborder-radius: 1em;\n\tcolor: var(--ion-color-light);\n\tfont-family: \"Gotham-Book\";\n\tfont-size: 1em;\n\tbackground: var(--ion-color-primary);\n\tpadding: 0.3em 0.8em;\n\tmargin: 1em 0em;\n}\n\n@mixin block-display {\n\tdisplay: block;\n\twidth: 100%;\n\tpadding-top: 0.2em;\n}\n\n@mixin sub-header-text {\n\tcolor: #000000;\n\tfont-family: \"Gotham-Bold\";\n\tfont-size: 1.3em;\n}\n\n@mixin sub-header {\n\tbackground: #F6F6F6;\n\twidth: 100%;\n\tborder-bottom: 1px solid #DBDCDD;\n}\n\n@mixin absolute-center {\n\tcolor: var(--ion-color-primary);\n\tfont-size: 1.5em;\n\tmargin: 0;\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\t-ms-transform: translate(-50%, -50%);\n\ttransform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
