import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { map, retry, timeout } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { fromEvent, merge, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  protected baseURL = environment.apiEndpoint;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(private http: HttpClient) {}

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
      });
  }

  pingServer() {
    this.checkNetworkStatus();
    return new Promise<any>((resolve, reject) => {
      (async () => {
        try {
          if (this.networkStatus) {
            this.http
              .get(`${this.baseURL}/System/alive`)
              .pipe(retry(10))
              .pipe(timeout(180000))
              .subscribe(
                (result: any) => {
                  resolve(true); // TODO - How to filter exception from Azure API's
                },
                (error: HttpErrorResponse) => {
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        } catch (e) {
          reject(e);
        }
      })();
    });
  }
}
