import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from './AppBaseService';
import { HttpParams, HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';
import { CODEDexie } from '../entity/dexie/CODE.dexie';

@Injectable({
  providedIn: 'root',
})
export class CodeService extends AppBaseServiceProvider {
  serviceName = 'Code';
  currentVersion = '1.0';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }

  async getCodes(): Promise<CODEDexie[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);
    try {
      const response = await this.http
        .get<CODEDexie[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(30000))
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getItems(
    eventId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<any[]> {
    let httpParams = new HttpParams();

    if (dateFrom) httpParams = httpParams.append('DateFrom', dateFrom);
    if (dateTo) httpParams = httpParams.append('DateTo', dateTo);
    httpParams = httpParams.append('api-version', this.currentVersion);

    try {
      const response = await this.http
        .get<any[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(300000))
        .toPromise();

      const helperValues = this.getNewVals(response);

      if (response.length > 0) {
        await this.syncLogerEntityManager.createUploadSyncLogForEvent(
          helperValues.provider,
          eventId,
          this.serviceName,
          helperValues.largestValue
        );
      }

      return helperValues.newVals;
    } catch (error) {
      throw error;
    }
  }

  async getRoles(): Promise<CODEDexie[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('api-version', this.currentVersion)
      .append('category', 'role');

    try {
      const response = await this.http
        .get<CODEDexie[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(30000))
        .toPromise();

      return response;
    } catch (error) {
      console.error('HTTP request failed:', error);
      throw error;
    }
  }
}
