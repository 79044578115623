import { AppBaseEntityD } from "../AppBaseEntityD";

export interface ISummary {
    Id: string;
    SummaryComment: string;
    EventId: number;
    ShiftDate: string;
    Role: string;
    IsUploaded: boolean;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class SummaryDexie extends AppBaseEntityD implements ISummary {
    constructor(summaryObject?: object) {
        super();
        for (const key in summaryObject) {
          if (summaryObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = summaryObject[key];
          }
        }
      }
      Id: string;
      SummaryComment: string;
      EventId: number;
      ShiftDate: string;
      Role: string;
      IsUploaded: boolean;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}