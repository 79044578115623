export class Constants {
  static AppName = 'Trunover Notes';
  // Analytics Events
  static SyncProgress = 'sync-progress';
  static Database = 'db-logs';
  static UnHandled = 'unhandled';
  static Login = 'login';
  static uploadError = 'upload-error';
  static httpError = 'http-error';
  static SyncError = 'sync-error';
  static generalError = 'general-error';
  // Pagination block size
  static pagingSize = 15;

  // SecureStorage
  static SecureStorageKey = 'fweapp';

  // DB Constants
  static DBName = 'fweapp.db';
  //static DBVersion = 1.0;type is accepting in number format only
  static DBVersion = '1.0'; 
  static BulkSize = 10000;

  // Work Orders per page
  static workOrderPagingNumber = 10;

  static CommentTagTypes = ['Lessons Learned', 'TAW', 'Outstanding Materials'];
}
