import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { NoticePage } from 'src/pages/notice/notice.page';
import { ModalController } from '@ionic/angular';



export const noticeGuard = (): Observable<boolean> | Promise<boolean> => {
  const modalCtrl = inject(ModalController);
  return new Promise<boolean>((resolve, reject) => {
    (async () => {
      const isNoticeAccepted = Boolean(
        JSON.parse(localStorage.getItem('isNoticeAccepted'))
      );
      if (isNoticeAccepted) {
        resolve(true);
      } else {
        const noticeCtrl = await modalCtrl.create({
          component: NoticePage,
          cssClass: 'hierarchy-modal',
          backdropDismiss: false,
          showBackdrop: false,
        });
        noticeCtrl.onDidDismiss().then(() => {
          resolve(true);
        });
        noticeCtrl.present();
      }
    })();
  });
};

