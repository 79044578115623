import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
@Injectable()
export class LoadingControllerHandler {
  constructor(private _loadingController: LoadingController, private _alertController: AlertController) { }

  loadingController = null;
  alertController = null;

  createLoading(config: any) {
    this.loadingController = this._loadingController.create(config);
  }

  async present() {
    await this.loadingController.present();
  }

  dismissAllLoaders() {
    this.loadingController.dismissAll();
    this.loadingController = null;
  }

  async presentAlert(errorId) {
    this.alertController = await this._alertController.create({
      header: 'Technical Error',
      subHeader: 'Please contact helpdesk with the following ID:',
      message: `${errorId}`,
      buttons: [{
        text: 'OK',
        handler: () => {
          this.alertController = null;
        }
      }
      ]
    });
    await this.alertController.present();
  }



}

