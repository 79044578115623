import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from '../entityService/AppBaseService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';
import { EventDexie } from '../entity/dexie/Event.dexie';

@Injectable({
  providedIn: 'root',
})
export class EventServiceProvider extends AppBaseServiceProvider {
  serviceName = 'Hierarchy';
  downloadOperationName = 'event';
  currentVersion = '1.0';

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }

  async getEventsBySite(site: string): Promise<EventDexie[]> {
    try {
      let httpParams = new HttpParams();
      httpParams = httpParams
        .append('api-version', this.currentVersion)
        .append('obs', site);
      const val = await this.http
        .get<any[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(30000))
        .toPromise();
      return val;
    } catch (error) {
      throw error;
    }
  }
}
