// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 15%;
  max-height: 50%;
  min-width: 150px;
  object-fit: contain;
  padding-bottom: 10px;
}

.title-ion-label {
  color: #751269;
  font-family: "Gotham-Bold";
  font-size: 27px;
  font-weight: bold;
  line-height: 30px;
  margin: auto;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/brand/brand.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":["img {\n  max-width: 15%;\n  max-height: 50%;\n  min-width: 150px;\n  object-fit: contain;\n  padding-bottom: 10px;\n}\n\n.title-ion-label {\n  color: #751269;\n  font-family: \"Gotham-Bold\";\n  font-size: 27px;\n  font-weight: bold;\n  line-height: 30px;\n  margin: auto;\n  text-align: center\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
