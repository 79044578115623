import { AppBaseEntityD } from "../AppBaseEntityD";

export interface IUser {
    Upn: string;
    Email: string;
    //Cai: string;
    FirstName: string;
    LastName: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class UserDexie extends AppBaseEntityD implements IUser {
    constructor(userObject?: object) {
        super();
        for (const key in userObject) {
          if (userObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = userObject[key];
          }
        }
      }
      Upn: string;
      Email: string;
      //Cai: string;
      FirstName: string;
      LastName: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}