import { AppBaseEntityD } from "../AppBaseEntityD";

export interface ILessonLearnt {
    Id: string;
    NoteId: string;
    LessonLearntComment: string;
    LessonLearned: string;
    People:string;
    Safety:string;  
    Recognition:string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class LessonLearntDexie extends AppBaseEntityD implements ILessonLearnt {
    constructor(LessonLearntObject?: object) {
        super();
        for (const key in LessonLearntObject) {
          if (LessonLearntObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = LessonLearntObject[key];
          }
        }
      }
      Id: string;
      NoteId: string;
      LessonLearntComment: string;
      LessonLearned: string;
      People:string;
      Safety:string;  
      Recognition:string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}