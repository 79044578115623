// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-hierarchy-cell {
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/hierarchy/hierarchy.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF","sourcesContent":["app-hierarchy-cell {\n  display: block;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
