import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { syncGuard } from 'src/guard/SyncGuard';
import { profileGuard } from 'src/guard/ProfileGuard';
import { noticeGuard } from 'src/guard/NoticeGuard';
import { dbGuard } from 'src/guard/DBGuard';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [MsalGuard, dbGuard, noticeGuard],
    loadChildren: () =>
      import('../pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'home/:provider/:eventId/:id',
    canActivate: [MsalGuard, dbGuard],
    loadChildren: () =>
      import('../pages/home/home.module').then((m) => m.HomePageModule),
  },

  {
    path: 'view-history/:provider/:eventId',
    canActivate: [MsalGuard, syncGuard, profileGuard],
    loadChildren: () =>
      import('../pages/view-history/view-history.module').then(
        (m) => m.ViewHistoryPageModule
      ),
  },
  {
    path: 'view-notes/:provider/:eventId/:id',
    canActivate: [MsalGuard, syncGuard, profileGuard],
    loadChildren: () =>
      import('../pages/view-notes/view-notes.module').then(
        (m) => m.ViewNotesPageModule
      ),
  },
  {
    path: 'add-notes/:provider/:eventId',
    canActivate: [MsalGuard, syncGuard, profileGuard],
    loadChildren: () =>
      import('../pages/add-notes/add-notes.module').then(
        (m) => m.AddNotesPageModule
      ),
  },

  {
    path: 'update-lessonlearnt/:provider/:eventId',
    canActivate: [MsalGuard, syncGuard, profileGuard],
    loadChildren: () =>
      import('../pages/update-lessonlearnt/update-lessonlearnt.module').then(
        (m) => m.UpdateLessonLearntPageModule
      ),
  },

  {
    path: 'add-notes-id/:provider/:eventId/:id',
    canActivate: [MsalGuard, syncGuard, profileGuard],
    loadChildren: () =>
      import('../pages/add-notes/add-notes.module').then(
        (m) => m.AddNotesPageModule
      ),
  },

  {
    path: 'note-details/:provider/:eventId/:id',
    canActivate: [MsalGuard, dbGuard, profileGuard],
    loadChildren: () =>
      import('../pages/note-details/note-details.module').then(
        (m) => m.NoteDetailsPageModule
      ),
  },
  {
    path: 'action-image',
    canActivate: [MsalGuard, dbGuard, profileGuard],
    loadChildren: () =>
      import('../pages/action-image/action-image.module').then(
        (m) => m.ActionImagePageModule
      ),
 
  },
  {
    path: 'hierarchy/:provider/:eventId',
    canActivate: [MsalGuard, syncGuard],
    loadChildren: () =>
      import('../pages/hierarchy/hierarchy.module').then(
        (m) => m.HierarchyPageModule
      ),
  },
  {
    path: 'events',
    loadChildren: () =>
      import('../pages/events/events.module').then((m) => m.EventsPageModule),
  },
  {
    path: 'notice',
    loadChildren: () =>
      import('../pages/notice/notice.module').then((m) => m.NoticePageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
