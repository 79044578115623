import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { OBSDexie } from '../entity/dexie/OBS.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class ObsEntityManagerService extends AppBaseEntityManager {
  protected _entityType = OBSDexie;
  dexieName = 'obs';
  constructor() {
    super();
  }

  getSites(): Promise<OBSDexie[]> {
    return new Promise<OBSDexie[]>((resolve, reject) => {
      db.obs.toArray().then((obs) => {
        resolve(
          obs.map((c) => {
            const obs = new OBSDexie(c);
            return obs;
          })
        );
      });
    });
  }

  saveObs(obs: OBSDexie[]): Promise<void> {
    return this.bulkInsert2(obs, this.dexieName);
  }
}
