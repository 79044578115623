import { Injectable } from '@angular/core';
import { Profile } from '../model/Profile';
import { User } from '../User';
import { IdentityServiceProvider } from '../otherService/IdentityService';
import { CodeService } from '../entityService/code.service';
import { OBSService } from '../entityService/obs.service';
import { EventServiceProvider } from '../otherService/EventService';
import { ProfileServiceProvider } from '../otherService/ProfileService';
import { EventEntityManagerService } from '../otherService/event-entity-manager.service';
import { CodeEntityManagerService } from '../entityManager/code-entity-manager.service';
import { ObsEntityManagerService } from '../entityManager/obs-entity-manager.service';
import { NoteEntityManagerService } from '../entityManager/note-entity-manager.service';
import { SummaryEntityManagerService } from '../entityManager/summary-entity-manager.service';
import { CODEDexie } from '../entity/dexie/CODE.dexie';
import { OBSDexie } from '../entity/dexie/OBS.dexie';
import { EventDexie } from '../entity/dexie/Event.dexie';
import { NoteDexie } from '../entity/dexie/Note.dexie';
import { SummaryDexie } from '../entity/dexie/Summary.dexie';

@Injectable({
  providedIn: 'root',
})
export class ProfileHelperService {
  profile: Profile;
  user: User;
  constructor(
    private readonly _identityService: IdentityServiceProvider,
    private codeService: CodeService,
    private readonly _obsService: OBSService,
    private eventService: EventServiceProvider,
    private readonly _profileService: ProfileServiceProvider,
    private eventManagerService: EventEntityManagerService,
    private codeManagerService: CodeEntityManagerService,
    private obsManagerService: ObsEntityManagerService,
    private noteManagerService: NoteEntityManagerService,
    private summaryEntityManagerService: SummaryEntityManagerService
  ) {}

  save() {
    // save profile to localstorage
    if (this.profile) {
      localStorage.setItem('Profile', JSON.stringify(this.profile));
    }
  }

  getProfile(): Promise<Profile> {
    return new Promise<Profile>((resolve, reject) => {
      (async () => {
        // get profile from localstorage
        this.user = await this.getUserInfo();

        this.profile = JSON.parse(localStorage.getItem('Profile')) as Profile;
        if (this.profile) {
          resolve(this.profile);
        } else {
          this._profileService
            .getProfile()
            .then((ret) => {
              this.profile = ret;
              this.save();
              if (this.profile) {
                resolve(this.profile);
              } else {
                reject('Profile not available');
              }
            })
            .catch((err) => {
              reject();
            });
        }

        // TODO: get profile from API
        // TODO: get user identity and assign it to profile
        // TODO: Error handling

        // If profile cannot be download from API, create profile
      })();
    });
  }

  updateProfile(profile: Profile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.validateProfile(profile)
        .then(async (validatedProfile) => {
          this._profileService
            .saveProfile(profile)
            .then((uploadedProfile) => {
              this.profile = uploadedProfile;
              this.save();
              resolve();
            })
            .catch((err) => {
              this.save();
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocalUserInfo(): User {
    return JSON.parse(localStorage.getItem('User')) as User;
  }

  getUserInfo(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      (async () => {
        let user: User = JSON.parse(localStorage.getItem('User')) as User;
        if (!user) {
          this._identityService
            .getUser()
            .then((usr) => {
              user = usr;
              localStorage.setItem('User', JSON.stringify(user));
              resolve(user);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve(user);
        }
      })();
    });
  }

  getRoles(): Promise<CODEDexie[]> {
    return new Promise<CODEDexie[]>((resolve, reject) => {
      let rolesRet: CODEDexie[];
      this.codeService
        .getRoles()
        .then(async (ret) => {
          await this.codeManagerService.saveCodes(ret);
        })
        .catch(async (err) => {
          rolesRet = await this.codeManagerService.getRoles();
        })
        .catch((err2) => {
          reject(err2);
        })
        .finally(() => {
          if (rolesRet) {
            resolve(rolesRet);
          } else {
            this.codeManagerService.getRoles().then((roles) => {
              rolesRet = roles;
              resolve(rolesRet);
            });
          }
        });
    });
  }

  getOBS(): Promise<OBSDexie[]> {
    return new Promise<OBSDexie[]>((resolve, reject) => {
      let OBSRet: OBSDexie[];
      this._obsService
        .getItems(-1, null, null) // obs is non-project specific, so use -1
        .then(async (rets) => {
          await this.obsManagerService.saveObs(rets);
        })
        .catch(async (err) => {
          OBSRet = await this.obsManagerService.getSites();
        })
        .catch((err2) => {
          reject(err2);
        })
        .finally(() => {
          if (OBSRet) {
            resolve(OBSRet);
          } else {
            this.obsManagerService.getSites().then((sites) => {
              OBSRet = sites;
              resolve(OBSRet);
            });
          }
        });
    });
  }

  getEvents(obsName: string): Promise<EventDexie[]> {
    return new Promise<EventDexie[]>((resolve, reject) => {
      let EventRet: EventDexie[];
      this.eventService
        .getEventsBySite(obsName)
        .then(async (rets) => {
          await this.eventManagerService.saveEvents(rets, obsName);
        })
        .catch(async (err) => {
          EventRet = await this.eventManagerService.getEvents(obsName);
        })
        .catch((err2) => {
          reject(err2);
        })
        .finally(() => {
          if (EventRet) {
            resolve(EventRet);
          } else {
            this.eventManagerService.getEvents(obsName).then((events) => {
              EventRet = events;
              resolve(EventRet);
            });
          }
        });
    });
  }

  initializeProfile(): Promise<Profile> {
    return new Promise<Profile>((resolve, reject) => {
      this.profile = new Profile();
      this.profile.preferredSOR = 'TaPro';
      this.profile.preferredRole = '';
      this.profile.preferredOBS = '';
      this.profile.preferredEvent = '';
      this.profile.preferredEventId = 0;
      this.profile.isCompleted = false;
      resolve(this.profile);
    });
  }

  validateProfile(profile: Profile): Promise<Profile> {
    return new Promise<Profile>((resolve, reject) => {
      if (
        profile.preferredEventId &&
        profile.preferredOBS &&
        profile.preferredRole
      ) {
        profile.isAdmin = false;
        profile.preferredSOR = 'TaPro';
        resolve(profile);
      } else {
        reject('invalid profile');
      }
    });
  }

  getAllOfflineNotes(): Promise<NoteDexie[]> {
    return new Promise<NoteDexie[]>((resolve, reject) => {
      this.noteManagerService
        .getAllOfflineNotes()
        .then((notes) => {
          resolve(notes);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getAllOfflineSummary(): Promise<SummaryDexie[]> {
    return new Promise<SummaryDexie[]>((resolve, reject) => {
      this.summaryEntityManagerService
        .getAllOfflineNotes()
        .then((summary) => {
          resolve(summary);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
