import { AppBaseEntityD } from "../AppBaseEntityD";

export interface INoteTag {
    Tag: string;
    NoteId: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class NoteTagDexie extends AppBaseEntityD implements INoteTag {
    constructor(NoteTag?: object) {
        super();
        for (const key in NoteTag) {
          if (NoteTag.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = NoteTag[key];
          }
        }
      }
      Tag: string;
      NoteId: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}