import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from '../entityManager/AppBaseEntityManager';
import { EventDexie } from '../entity/dexie/Event.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class EventEntityManagerService extends AppBaseEntityManager {
  protected _entityType = EventDexie;
  dexieName = 'event';
  constructor() {
    super();
  }

  getEvents(obs: string): Promise<EventDexie[]> {
    return new Promise<EventDexie[]>((resolve, reject) => {
      (async () => {
     await db.event
        .where('ForeignParentEntity')
        .equals(obs)
        .toArray()
        .then((events) => {
          const data = events.filter((e) => e.IsDeleted == false);
          resolve(
            data.map((e) => {
              const event = new EventDexie(e);
              return event;
            })
          );
        });
      })();
    });
  }

  saveEvents(events: EventDexie[], obs: string): Promise<void> {
    events.forEach((event) => (event.ForeignParentEntity = obs));
    return this.bulkInsert2(events, this.dexieName);
  }
}
