// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-wrapper {
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  display: flex;
  min-width: 250px;
  min-height: 100px;
  width: 250px;
  height: 100px;
  margin: auto;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  align-items: center;
  color: var(--ion-color-primary);
  font-family: "Gotham-Book";
}`, "",{"version":3,"sources":["webpack://./src/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,oBAAA;EAEA,0BAAA;EAEA,wBAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,iBAAA;EACA,mBAAA;EACA,+BAAA;EACA,0BAAA;AAAF","sourcesContent":[".loading-wrapper {\n  padding-left: unset;\n  padding-right: unset;\n  -webkit-padding-start: 30px;\n  padding-inline-start: 30px;\n  -webkit-padding-end: 30px;\n  padding-inline-end: 30px;\n  display: flex;\n  min-width: 250px;\n  min-height: 100px;\n  width: 250px;\n  height: 100px;\n  margin: auto;\n  border-radius: 16px;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: white;\n  align-items: center;\n  color: var(--ion-color-primary);\n  font-family: 'Gotham-Book';\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
