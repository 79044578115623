import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { SummaryDexie } from '../entity/dexie/Summary.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryEntityManagerService extends AppBaseEntityManager {
  protected _entityType = SummaryDexie;
  dexieName = 'summary';

  constructor() {
    super();
  }

  saveSummary(summary: SummaryDexie[]): Promise<void> {
    return this.bulkInsert2(summary, this.dexieName);
  }

  getAllOfflineNotes(): Promise<SummaryDexie[]> {
    return new Promise<SummaryDexie[]>((resolve, reject) => {
      try {
        db.summary
          .filter((e) => e.IsUploaded != true && e.IsDeleted != true)
          .toArray()
          .then((r) => {
            resolve(r as SummaryDexie[]);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSummary(summry: SummaryDexie[]): Promise<SummaryDexie[]> {
    try {
      const summaryIds = summry.map(({ Id }) => Id);
      const summary = await db.summary.where('Id').anyOf(summaryIds).toArray();
      return summary as SummaryDexie[];
    } catch (error) {
      throw error;
    }
  }

  getOfflineSummary(
    provider: string,
    eventId: number
  ): Promise<SummaryDexie[]> {
    return new Promise<SummaryDexie[]>((resolve, reject) => {
      try {
        db.summary
          .filter((e) => e.EventId == eventId)
          .toArray()
          .then((r) => {
            resolve(r as SummaryDexie[]);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSummary(summary: SummaryDexie): Promise<void> {
    try {
      await db.summary.put(summary);
    } catch (err) {
      throw err;
    }
  }
}
