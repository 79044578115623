import { inject } from '@angular/core';
import { db } from 'src/services/dexie.service';
import { LoadingService } from 'src/services/loading.service';



export const dbGuard = async (): Promise<boolean> => {
  const loadingService = inject(LoadingService);
  loadingService.present();
  return new Promise<boolean>((resolve) => {
    (async () => {
      await db.open().catch((e) => console.log(e));
      loadingService.dismiss();
      resolve(true);
    })();
  });
};
