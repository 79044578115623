import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  DoCheck,
} from '@angular/core';
import { HierarchySelectionMode } from 'src/data/InternalTypes';
import { Observable, from } from 'rxjs';
import { HierarchyEntityManagerService } from 'src/data/entityManager/hierarchy-entity-manager.service';
import { Router } from '@angular/router';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';

@Component({
  selector: 'app-hierarchy-cell',
  templateUrl: './hierarchy-cell.component.html',
  styleUrls: ['./hierarchy-cell.component.scss'],
})
export class HierarchyCellComponent implements OnChanges, DoCheck {
  @Input() hierarchy: HierarchyDexie;
  @Input() mode: HierarchySelectionMode = HierarchySelectionMode.single;
  @Input() hideDeleted: boolean;
  @Output() selectedHierarchy = new EventEmitter();
  @Output() expandedHierarchy = new EventEmitter();
  noteCount$: Observable<number>;
  isDisplayed = false;

  constructor(
    private router: Router,
    private hierarchyEntityManager: HierarchyEntityManagerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'hierarchy') {
        const chng = changes[propName];
        this.hierarchy = chng.currentValue;
        this.updateView();
      }
    }
  }

  ngDoCheck() {
    this.updateView();
  }

  expandArrow() {
    this.hierarchy.isExpanded = !this.hierarchy.isExpanded;
    this.expandedHierarchy.emit(this.hierarchy);
  }

  getStyle() {
    return this.hierarchy.isExpanded ? 'selected' : 'unselected';
  }

  checkHierarchy() {
    this.selectedHierarchy.emit(this.hierarchy);
  }

  updateView() {
    this.isDisplayed =
      (this.hierarchy.parent === undefined ||
        this.hierarchy.parent.isExpanded) &&
      (this.hierarchy.isMatched === undefined || this.hierarchy.isMatched);

    if (
      this.mode === HierarchySelectionMode.viewWithNotes &&
      this.noteCount$ === undefined &&
      (this.hierarchy.parent === undefined || this.hierarchy.parent.isExpanded)
    ) {
      this.noteCount$ = from(
        this.hierarchyEntityManager.getNotesCountForHierarchy(this.hierarchy)
      );
    }
  }

  viewHierarchyNotes() {
    this.router.navigate([
      'view-notes',
      this.hierarchy.Provider,
      this.hierarchy.EventId,
      this.hierarchy.ForeignId,
    ]);
  }
}
