import { Injectable } from '@angular/core';
import { NoteCommentEntityManagerService } from './note-comment-entity-manager.service';
import { NoteLessonlearntEntityManagerService } from './note-lessonlearnt-entity-manager.service';
import { NoteTagEntityManagerService } from './note-tag-entity-manager.service';
import { Filter, DurationType } from '../InternalTypes';
import { LoadingService } from 'src/services/loading.service';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import * as moment from 'moment';
import { NoteAttachmentEntityManagerService } from './note-attachment-entity-manager.service';
import { db } from 'src/services/dexie.service';
import { INote, NoteDexie } from '../entity/dexie/Note.dexie';
import { NoteCommentDexie } from '../entity/dexie/NoteComment.dexie';
import { LessonLearntDexie } from '../entity/dexie/LeasonLearnt.dexie';
import { AttachmentDexie } from '../entity/dexie/Attachment.dexie';
import { NoteTagDexie } from '../entity/dexie/NoteTag.dexie';
import { HierarchyDexie } from '../entity/dexie/Hierarchy.dexie';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class NoteEntityManagerService extends AppBaseEntityManager {
  protected _entityType =  NoteDexie;
  dexieName = 'notes';
  constructor(
    private noteCommentEntityManager: NoteCommentEntityManagerService,
    private noteLessonLearntEntityManager: NoteLessonlearntEntityManagerService,
    private noteTagEntityManager: NoteTagEntityManagerService,
    private loading: LoadingService,
    private noteAttachmentEntityManager: NoteAttachmentEntityManagerService
  ) {
    super();
  }

  async getNotesForHierarchy(
    hierarchy: HierarchyDexie,
    user?: string
   ): Promise<NoteDexie[]> {
    if (!hierarchy) {
       throw new Error('hierarchy object is required');
    }
    const noteQueryBuilder = db.notes.filter(
       (n) =>
         n.ParentId == hierarchy.ForeignId &&
         n.ParentEntity === hierarchy.ForeignEntity &&
         n.IsDeleted !== true
    );
   
    if (user) {
       noteQueryBuilder.filter((n) => n.CreatedByUPN === user);
    }
   
    const notes = await noteQueryBuilder.reverse().sortBy('UpdatedDate');
   
    for (const note of notes) {
       note.Comments = await this.noteCommentEntityManager.getCommentsForNote(note.Id);
       note.Tags = await this.noteTagEntityManager.getTagsForNote(note.Id);
    }
   
    return notes.map((n) => new NoteDexie(n));
   }

  getNotesCountForHierarchy(
    hierarchy: HierarchyDexie,
    user?: string
  ): Promise<number> {
    if (hierarchy) {
      const noteQueryBuilder = db.notes.filter(
        (e) =>
          e.ParentId == hierarchy.ForeignId &&
          e.ParentEntity === hierarchy.ForeignEntity &&
          e.IsDeleted != true
      );

      if (user) {
        noteQueryBuilder.filter((e) => e.CreatedByUPN === user);
      }
      return noteQueryBuilder.count();
    } else {
      return null;
    }
  }

  async createNote(note: NoteDexie): Promise<void> {
    try {
      await db.notes.put(note);
      await this.noteCommentEntityManager.deleteCommentsForNote(note.Id);
      for (let index = 0; index < note.Comments.length; index++) {
        if (
          note.Comments[index].CreatedByUPN === null ||
          note.Comments[index].CreatedByUPN === undefined
        ) {
          note.Comments[index].CreatedByUPN = note.CreatedByUPN;
          note.Comments[index].UpdatedByUPN = note.UpdatedByUPN;
          note.Comments[index] = new NoteCommentDexie(note.Comments[index]);
        }
      }
      await this.noteCommentEntityManager.saveComments(note.Comments);
      await this.noteLessonLearntEntityManager.deletelessonlearntForNote(
        note.Id
      );
      await this.noteLessonLearntEntityManager.saveLessonLearnt(
        note.Lessonlearnt
      );
      const tagsForNote = [];
      for (const t of note.Tags) {
        const tag = new NoteTagDexie();
        tag.Tag = t;
        tag.NoteId = note.Id;
        tag.CreatedByUPN = note.CreatedByUPN;
        tag.UpdatedByUPN = note.UpdatedByUPN;
        tag.IsDeleted = false;
        tagsForNote.push(tag);
      }
      await this.noteTagEntityManager.deleteTagsForNote(note.Id);
      await this.noteTagEntityManager.saveTags(tagsForNote);
      await this.noteAttachmentEntityManager.deleteAttachmentsForNote(note.Id);
      await this.noteAttachmentEntityManager.saveAttachments(note.Attachments);
    } catch (error) {
      throw error;
    }
  }

  saveNotes(notes: NoteDexie[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.bulkInsert2(notes, this.dexieName)
        .then(async (_) => {
          let comments = [];
          let tags = [];
          let attachments = [];
          let lessonlearnt = [];
          const NoteIdArray = notes.map((note) => note.Id);
          for (const note of notes) {
            if (note.Comments) {
              const commentsForNote = note.Comments.map((val) => {
                return new NoteCommentDexie(val);
              });
              comments = comments.concat(commentsForNote);
            }

            if (note.Lessonlearnt) {
              const LLforNote = note.Lessonlearnt.map((val) => {
                return new LessonLearntDexie(val);
              });
              lessonlearnt = lessonlearnt.concat(LLforNote);
            }

            if (note.Tags) {
              const tagsForNote = note.Tags.map((val) => {
                const tag = new NoteTagDexie();
                tag.Tag = val;
                tag.NoteId = note.Id;
                return tag;
              });
              tags = tags.concat(tagsForNote);
            }

            if (note.Attachments) {
              const attachmentForNote = note.Attachments.map((val) => {
                delete val['fileArray'];
                return new AttachmentDexie(val);
              });

              attachments = attachments.concat(attachmentForNote);
            }
          }
          await this.noteCommentEntityManager.deleteCommentsForNotes(
            NoteIdArray
          );
          await this.noteCommentEntityManager.bulkInsert2(
            comments,
            'notecomment'
          );

          await this.noteLessonLearntEntityManager.deletelessonlearntForNotes(
            NoteIdArray
          );
          await this.noteLessonLearntEntityManager.bulkInsert2(
            lessonlearnt,
            'lessonlearnt'
          );

          await this.noteTagEntityManager.deleteTagsForNotes(NoteIdArray);
          await this.noteTagEntityManager.bulkInsert2(tags, 'notetag');

          await this.noteAttachmentEntityManager.deleteAttachmentsForNotes(
            NoteIdArray
          );
          await this.noteAttachmentEntityManager.bulkInsert2(
            attachments,
            'attachment'
          );
          resolve(); // since no method is called inside it
        })
        .catch((error) => reject(error));
    });
  }
  data: any;
  async getFilteredNotes(
    filter: Filter,
    eventId?: number
  ): Promise<NoteDexie[]> {
    try {
      this.loading.present();
      let filterNotes = [];
      let notesQ = db.notes;

      const hasTags = filter?.tags?.length > 0;
      if (hasTags) {
        const tagStr = filter.tags;
        const tag = (await db.notetag.where('Tag').anyOf(tagStr).toArray()).map(
          (item) => item.NoteId
        );
        this.data = await notesQ.where('Id').anyOf(tag).toArray();
      }

      if (eventId) {
        if (hasTags) {
          this.data = this.data.filter((e) => e.EventId == eventId);
        } else {
          this.data = await notesQ
            .where('EventId')
            .equals(eventId)
            .reverse()
            .sortBy('UpdatedDate');
        }
      }

      this.data = await this._getNotesFilterQuery(filter, eventId, notesQ);
      const notes = await this.data;
      this.loading.dismiss();
      return notes as NoteDexie[];
    } catch (e) {
      throw e;
    }
  }

  private async _getNotesFilterQuery(
    filter: Filter,
    eventId: number,
    notesQuery: Dexie.Table<INote, string>
  ) {
    if (filter?.hierarchy?.length > 0) {
      const hierarchyIds = [];
      for (const h of filter.hierarchy) {
        hierarchyIds.push(h.ForeignId);
      }
      this.data = this.data.filter((e) => hierarchyIds.includes(e.ParentId));
    }

    if (filter?.authors?.length > 0) {
      const authorStr = filter?.authors.map((author) => author.Upn);
      this.data = await notesQuery
        .where('CreatedByUPN')
        .anyOf(authorStr)
        .or('UpdatedByUPN')
        .anyOf(authorStr)
        .filter((e) => e.EventId == eventId)
        .toArray();
    }

    if (filter?.roles?.length > 0) {
      let roles = filter?.roles.join(`','`);
      roles = `'` + roles + `'`;
      this.data = this.data.filter((item) => filter?.roles.includes(item.Role));
    }

    // TODO: Modify Date comparisons
    if (filter?.shiftStartDate) {
      let date = new Date(filter?.shiftStartDate);
      date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
      date.setUTCHours(0);
      const dateStr = date.toISOString();
      this.data = this.data.filter((e) => e.ShiftDate >= dateStr);
    }
    if (filter?.shiftEndDate) {
      let date = new Date(filter?.shiftEndDate);
      date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
      date.setUTCHours(0);
      const dateStr = date.toISOString();
      this.data = this.data.filter((e) => e.ShiftDate <= dateStr);
    }
    if (filter?.shiftNames?.length > 0) {
      this.data = this.data.filter((e) =>
        filter?.shiftNames.includes(e.ShiftName)
      );
    }
    // Review time zone data are storage on UTC Time
    // get time on house
    const tm = moment(new Date()).utcOffset() / 60;
    if (filter?.duration === DurationType.halfDay) {
      let currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + (-12 - tm));
      const value = currentDate.toISOString();
      this.data = this.data.filter((e) => e.CreatedDate >= value);
    } else if (filter?.duration === DurationType.fullDay) {
      let currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + (-24 - tm));
      const value = currentDate.toISOString();
      this.data = this.data.filter((e) => e.CreatedDate <= value);
    } else {
      if (filter?.createdDateFrom) {
        let value = new Date(filter?.createdDateFrom).toISOString();
        this.data = this.data.filter((e) => e.CreatedDate >= value);
      }
      if (filter?.createdDateTo) {
        let value = new Date(filter?.createdDateTo).toISOString();

        this.data = this.data.filter((e) => e.CreatedDate <= value);
      }
    }

    return this.data;
  }

  getAllOfflineNotes(): Promise<NoteDexie[]> {
    return new Promise<NoteDexie[]>((resolve, reject) => {
      try {
        db.notes
          .filter((e) => e.IsUploaded === false && e.IsDeleted === false)
          .toArray()
          .then((note) => {
            resolve(note as NoteDexie[]);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getOfflineNotes(provider: string, eventId: number): Promise<NoteDexie[]> {
    return new Promise<NoteDexie[]>((resolve, reject) => {
      try {
        db.notes
          .filter(
            (e) =>
              e.EventId == eventId &&
              e.IsUploaded !== true &&
              e.IsDeleted !== false
          )
          .toArray()
          .then((n) => {
            resolve(n as NoteDexie[]);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getNote(noteId: string): Promise<NoteDexie> {
    return new Promise<NoteDexie>((resolve, reject) => {
      try {
        db.notes
          .filter((e) => e.Id == noteId)
          .first()
          .then((n) => {
            resolve(n as NoteDexie);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
