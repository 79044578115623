import { AppBaseEntityD } from "../AppBaseEntityD";
import { AttachmentDexie } from "./Attachment.dexie";
import { LessonLearntDexie } from "./LeasonLearnt.dexie";
import { NoteCommentDexie } from "./NoteComment.dexie";

export interface INote {
    Id: string;
    ParentId: number;
    Type: string;
    EventId: number;
    ParentEntity: string;
    Role: string;
    ShiftDate: string;
    ShiftName: string;
    IsUploaded: boolean;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;

    Comments: NoteCommentDexie[];
    Lessonlearnt: LessonLearntDexie[];
    Tags: string[];
    Attachments: AttachmentDexie[];
}

export class NoteDexie extends AppBaseEntityD implements INote {
    constructor(NoteObject?: object) {
        super();
        for (const key in NoteObject) {
          if (NoteObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = NoteObject[key];
          }
        }
      }
      Id: string;
      ParentId: number;
      Type: string;
      EventId: number;
      ParentEntity: string;
      Role: string;
      ShiftDate: string;
      ShiftName: string;
      IsUploaded: boolean;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
  
      Comments: NoteCommentDexie[];
      Lessonlearnt: LessonLearntDexie[];
      Tags: string[];
      Attachments: AttachmentDexie[];
}