import { ErrorHandler, Injectable } from "@angular/core";
import { MonitoringService } from "./logging.service";

@Injectable()
export class ErrorServiceHandler extends ErrorHandler
{
    constructor(private monitoringService: MonitoringService){
        super();
    }

    handleError(error: Error) {
        this.monitoringService.logException(error);
    }
}   