import { AppBaseEntityD } from "../AppBaseEntityD";

export interface IEvent {
    ForeignId: number;
    ForeignParentId: number;
    EventId: number;
    ForeignEntity: string;
    ForeignParentEntity: string;
    DisplayId: string;
    Name: string;
    Provider: string;
    Sequence: number;
    Category: string;
    IsCompleted: boolean;
    InstanceName: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class EventDexie extends AppBaseEntityD implements IEvent {
    constructor(EventObject?: object) {
        super();
        for (const key in EventObject) {
          if (EventObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = EventObject[key];
          }
        }
      }
      ForeignId: number;
      ForeignParentId: number;
      EventId: number;
      ForeignEntity: string;
      ForeignParentEntity: string;
      DisplayId: string;
      Name: string;
      Provider: string;
      Sequence: number;
      Category: string;
      IsCompleted: boolean;
      InstanceName: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}