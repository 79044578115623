// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  margin: 0.5em 0em;
}

div {
  display: block;
}

.noteType {
  color: #751269;
  font-family: "Gotham-Book";
  font-size: 1em;
  font-weight: bold;
}

.noteCreatedDate {
  color: #A9A9A9;
  font-family: "Gotham-Book";
  font-size: 0.8em;
  font-weight: 300;
  text-align: right;
}

.noteDetail {
  color: #4A4A4A;
  font-family: "Gotham-Book";
  font-size: 1em;
  font-weight: 300;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noteShiftDate {
  color: #4A4A4A;
  font-family: "Gotham-Book";
  font-size: 1em;
  font-weight: 500;
  line-height: 17px;
}`, "",{"version":3,"sources":["webpack://./src/components/note-list/note-list.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":["ion-label {\n  margin: 0.5em 0em;\n}\n\ndiv {\n  display: block;\n}\n\n.noteType {\n  color: #751269;\n  font-family: \"Gotham-Book\";\n  font-size: 1em;\n  font-weight: bold;\n}\n\n.noteCreatedDate {\n  color: #A9A9A9;\n  font-family: \"Gotham-Book\";\n  font-size: 0.8em;\n  font-weight: 300;\n  text-align: right;\n}\n\n.noteDetail {\n  color: #4A4A4A;\n  font-family: \"Gotham-Book\";\n  font-size: 1em;\n  font-weight: 300;\n  white-space: pre;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.noteShiftDate {\n  color: #4A4A4A;\n  font-family: \"Gotham-Book\";\n  font-size: 1em;\n  font-weight: 500;\n  line-height: 17px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
