import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { of, from } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';
import { SyncService } from 'src/services/sync/SyncService';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { dbGuard } from './DBGuard';

export const syncGuard = (next: ActivatedRouteSnapshot) => {
  const loadingService = inject(LoadingService);
  const router = inject(Router);
  const syncService = inject(SyncService);
  const provider = next.params?.provider;
  const eventId = next.params?.eventId;
  const isCompleted = next.params?.isCompleted;
  const currentNavigation = router.getCurrentNavigation();
  if (currentNavigation?.extras?.state?.isSyncEnabled) {
    loadingService.present();
    return from(dbGuard()).pipe(
      concatMap(() =>
        syncService.syncDataForEvent(provider, +eventId, Boolean(isCompleted))
      ),
      catchError((syncGuardError) => {
        return of(true);
      }),
      (map(() => {
        return true;
      }),
      finalize(() => loadingService.dismiss()))
    );
  } else {
    return of(true);
  }
};
