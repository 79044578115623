import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../User';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdentityServiceProvider  {
  serviceName = 'System';
  downloadOperationName = 'identity';
  currentVersion = '1.0';

  constructor(
    private readonly _http: HttpClient
  ) {}

  getUser(): Promise<User> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<User>((resolve, reject) => {
      (async () => {
      this._http
        .get<User>(
          `${environment.apiEndpoint}/${this.serviceName}/${this.downloadOperationName}`,
          {
            params: httpParams
          }
        )
        .subscribe(
          val => {
            resolve(val);
          },
          error => {
            reject(error);
          }
        );
    })();
  });
  }
}
