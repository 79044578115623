import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { NoteCommentDexie } from '../entity/dexie/NoteComment.dexie';
import { NoteDexie } from '../entity/dexie/Note.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class NoteCommentEntityManagerService extends AppBaseEntityManager {
  protected _entityType = NoteCommentDexie;
  dexieName = 'notecomment';
  constructor() {
    super();
  }

  async getCommentsForNote(noteId: string): Promise<NoteCommentDexie[]> {
    try {
      const noteComment = await db.notecomment
        .filter((e) => e.NoteId === noteId)
        .toArray();
      // const language = localStorage.getItem('language');
      // if (language === 'kaz') {
      //   for (let child of noteComment as NoteCommentDexie[]) {
      //     child.Text = child.KazakText;
      //   }
      // }
      // if (language === 'ru') {
      //   for (let child of noteComment as NoteCommentDexie[]) {
      //     child.Text = child.RussianText;
      //   }
      // }
      return noteComment as NoteCommentDexie[];
    } catch (error) {
      throw error;
    }
  }

  async getCommentsForNotes(notes: NoteDexie[]): Promise<NoteCommentDexie[]> {
    try {
      const notesIds = notes.map(({ Id }) => Id);
      const noteComment = await db.notecomment
        .where('NoteId')
        .anyOf(notesIds)
        .toArray();

      // const language = localStorage.getItem('language');
      // if (language === 'kaz') {
      //   for (let child of noteComment as NoteCommentDexie[]) {
      //     child.Text = child.KazakText;
      //   }
      // }
      // if (language === 'ru') {
      //   for (let child of noteComment as NoteCommentDexie[]) {
      //     child.Text = child.RussianText;
      //   }
      // }
      return noteComment as NoteCommentDexie[];
    } catch (error) {
      throw error;
    }
  }

  saveComments(comments: NoteCommentDexie[]): Promise<void> {
    return this.bulkInsert2(comments, this.dexieName);
  }

  async deleteCommentsForNote(noteId: string): Promise<void> {
    try {
      await db.notecomment.where('NoteId').equals(noteId).delete();
    } catch (error) {
      throw error;
    }
  }

  async deleteCommentsForNotes(noteIds: string[]): Promise<void> {
    try {
      await db.notecomment.where('NoteId').anyOf(noteIds).delete();
    } catch (error) {
      throw error;
    }
  }
}
