import { AppErrorType } from './InternalTypes';

export class AppError {
  type: AppErrorType;
  params: any;
  details: any;
  constructor(type: AppErrorType, params: any, details?: any) {
    this.type = type;
    this.params = params;
    this.details = details;
  }
}
