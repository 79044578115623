import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CameraOutput } from 'src/data/model/camera-output.model';

@Injectable({
  providedIn: 'root',
})
export class CameraProvider {
  public os: string;
  private imageToEdit;
  constructor(private platform: Platform) // private camera: Camera,
  {}

  setImage(image) {
    this.imageToEdit = image;
  }

  public getImage() {
    return this.imageToEdit;
  }
  /**
   * Method to capture picture
   */
  takePictureNormal(): Promise<CameraOutput> {
    return new Promise<any>((resolve, reject) => {
      let saveToAlbum = false;
      if (this.os === 'android' || this.os === 'ios') {
        saveToAlbum = true;
      }
    });
  }

   takePicture(): Promise<any> {
    let cameraOutput: any = null;
    return new Promise<any>((resolve, reject) => {
      (async () => {
        cameraOutput = this.takePictureNormal();
        resolve(cameraOutput);
      })();
    });
  }
}
