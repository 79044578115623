import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { CODEDexie } from '../entity/dexie/CODE.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class CodeEntityManagerService extends AppBaseEntityManager {
  dexieName = 'code';
  protected _entityType = CODEDexie;
  constructor() {
    super();
  }
  async getCodes(): Promise<CODEDexie[]> {
    try {
      const c = await db.code.toArray();
      return c as CODEDexie[];
    } catch (error) {
      throw error;
    }
  }

  saveCodes(codes: CODEDexie[]): Promise<void> {
    return this.bulkInsert2(codes, this.dexieName);
  }

  getRoles(): Promise<CODEDexie[]> {
    return new Promise<CODEDexie[]>((resolve) => {
      (async () => {
        await db.code
          .where('Category')
          .equals('Role')
          .toArray()
          .then((code) => {
            resolve(code as CODEDexie[]);
          });
      })();
    });
  }

  // Retrive only the category tag
  async getTags(): Promise<CODEDexie[]> {
    try {
      const tag = await db.code.where('Category').equals('Tag').toArray();
      return tag as CODEDexie[];
    } catch (error) {
      throw error;
    }
  }
}
