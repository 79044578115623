import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Platform,
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { CameraProvider } from 'src/shared/services/camera.provider';
import { File } from '@ionic-native/file/ngx';
import { ADALProvider } from 'src/shared/adal/adal';
import { AttachmentHelperService } from 'src/data/helper/attachment-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageViewerComponent } from '../image-viewer/image-viewer';
import { NetworkService } from 'src/services/network.service';
import { NoteAttachmentEntityManagerService } from 'src/data/EntityManagerIndex';
import { EventService } from 'src/shared/event';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject, map } from 'rxjs';
import { AttachmentDexie } from 'src/data/entity/dexie/Attachment.dexie';
import { db } from 'src/services/dexie.service';
@Component({
  selector: 'photo-only',
  templateUrl: 'photo-only.html',
  styleUrls: ['./photo-only.scss'],
})
export class PhotoOnlyComponent implements OnInit {
  @Input() noteId: any;
  @Input() createMode = true;
  @Input() mode: number;
  attachments = new Array<AttachmentDexie>();
  private attachmentsToDelete = new Array<AttachmentDexie>();
  private loader: any;
  downloading: boolean;
  cameraOn: boolean = false;
  images: { base64: string }[] = [];

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async openCamera() {
    if (this.cameraOn) {
      this.cameraOn = !this.cameraOn;
      this.webcamImage = null;
    } else {
      this.loader = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Loading Camera...',
      });
      await this.loader.present();
      this.cameraOn = !this.cameraOn;
      await this.delay(14000);

      await this.loader.dismiss();
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  confirmImage(): void {
    this._openPhotoEditor(this.webcamImage.imageAsBase64);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  constructor(
    private router: Router,
    private platform: Platform,
    private cameraProvider: CameraProvider,

    private events: EventService,
    public loadingController: LoadingController,
    private adalService: ADALProvider,
    private file: File,
    private attachmentService: AttachmentHelperService,
    private sanitizer: DomSanitizer,
    private alert: AlertController,
    private modal: ModalController,
    private networkService: NetworkService,
    private noteAttachmentEntityManagerService: NoteAttachmentEntityManagerService
  ) {}

  ngOnInit() {
    this.loadAttachments();
  }
  async loadAttachments() {
    // ionic upgrade commit
    this.attachments =
      await this.noteAttachmentEntityManagerService.getAttachmentsForNote(
        this.noteId
      );

    for (const attachment of this.attachments) {
      attachment.FormatedFile = attachment.FileURI
        ? await this.blobToBase64(
            attachment.FileURI.substring(
              0,
              attachment.FileURI.lastIndexOf(attachment.FileName)
            ),
            attachment.FileName
          )
        : null;
    }
  }

  async photo() {    
      // workaround to simulate take picture on camera
      this._openPhotoEditor(this.webcamImage.imageAsDataUrl);   
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        alert('Please select only image files.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        this._openPhotoEditor(base64);
      };
      reader.readAsDataURL(file);
    }
  }

  async filePick() {  
      const message = await this.alert.create({
        header: 'Something  went wrong',
        message: 'This feature is only available on mobile',
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await message.present();
      await message.onDidDismiss();    
  }  

  async onImageClick(attachment: AttachmentDexie) {
    const modal = await this.modal.create({
      component: ImageViewerComponent,
      cssClass: 'hierarchy-modal',
      componentProps: attachment,
    });
    modal.present();
  }

  async removeImage(attachment: AttachmentDexie) {
    const attachmentRepository = db.attachment;
    const index = this.attachments.indexOf(attachment, 0);
    if (attachment.Id === '') {
      this.attachments.splice(index, 1);
      const att = await attachmentRepository
        .where('TimeStamp')
        .equals(attachment.TimeStamp);
      if (att) {
        await att.delete();
      }
    } else {
      attachment.IsDeleted = true;
      attachment.IsUploaded = false;
    }
  }

  enablebuttons() {
    return this.attachments.filter((att) => !att.IsDeleted).length === 2;
  }

  hideViewImages(): boolean {
    const attachmentLength = this.attachments.length;
    const downloadedImagesLength = this.attachments.filter(
      (attachment) => attachment.FileURI
    ).length;   
      return false;  
  }

  private async _openPhotoEditor(photo: string): Promise<void> {
    this.events.formRefreshSource$.pipe(
       map(async (saveImage: any) => {
         if (saveImage !== 'cancel') {
           this.webcamImage = null;
           this.cameraOn = false;
           this.loader = await this.loadingController.create({
             cssClass: 'my-custom-class',
             message: 'Saving Picture...',
           });
           await this.loader.present();
           await this.save(saveImage);
         }
         return;
       })
    ).toPromise(); 
   
    this.cameraProvider.setImage(photo);
    this.router.navigate(['action-image']);
   }

  private async save(photo: string) {
    try {
      const attachment = new AttachmentDexie();
      attachment.Id = '';
      attachment.Format = 'image';
      attachment.NoteId = this.noteId;
      attachment.TimeStamp = new Date().getTime();
      attachment.FileName = `note_image_${attachment.TimeStamp}.png`;
      attachment.IsDeleted = false;
      attachment.IsUploaded = false;
      attachment.IsDownloaded = false;

      attachment.UpdatedDate = new Date().toISOString();
      attachment.UpdatedByUPN = this.adalService.getUPN();
      attachment.CreatedByUPN = attachment.UpdatedByUPN;
      attachment.CreatedDate = attachment.UpdatedDate;   
        const contentType = this.getContentType(photo);
        const DataBlob = this.base64toBlob(photo, contentType);
        attachment.FormatedFile = photo;
        attachment.BlobFile = DataBlob;
        this.attachments.push(attachment);
        this.loader.dismiss();    
    } catch (err) {
      this.loader.dismiss();
    }
  }

  // here is the method is used to write a file in storage
  public async writeFile(base64Data: any, attachment: AttachmentDexie) {
    const contentType = this.getContentType(base64Data);
    const DataBlob = this.base64toBlob(base64Data, contentType);
    // here iam mentioned this line this.file.externalRootDirectory is a native pre-defined file path storage. You can change a file path whatever pre-defined method.
    let filePath = '';
    filePath = this.file.dataDirectory + 'attachments/user/';
    this.file
      .checkDir(filePath, attachment.NoteId.toString())
      .then(async () => {
        filePath += attachment.NoteId.toString();
        this.saveImageBlobAndAttachmentEntity(
          filePath,
          attachment.FileName,
          DataBlob,
          contentType,
          attachment
        );
      })
      .catch(async () => {
        this.file
          .createDir(filePath, attachment.NoteId.toString(), false)
          .then(() => {
            filePath += attachment.NoteId.toString();
            this.saveImageBlobAndAttachmentEntity(
              filePath,
              attachment.FileName,
              DataBlob,
              contentType,
              attachment
            );
          })
          .catch(() => {
            this.saveImageBlobAndAttachmentEntity(
              filePath,
              attachment.FileName,
              DataBlob,
              contentType,
              attachment
            );
          });
      });
  }

  saveImageBlobAndAttachmentEntity(
    filePath: string,
    fileName: string,
    DataBlob: any,
    contentType: any,
    attachment: AttachmentDexie
  ) {
    this.file
      .writeFile(filePath, fileName, DataBlob, contentType)
      .then(async (fileEntry) => {
        attachment.FileURI = fileEntry.nativeURL;
        attachment.FormatedFile = await this.blobToBase64(
          filePath + '/',
          fileEntry.name
        );
        attachment.BlobFile = DataBlob;
        this.attachments.push(attachment);
        if (this.downloading) {
          attachment.IsDownloaded = true;
          await db.attachment.put(attachment);
          this.downloading = false;
        }
        this.loader.dismiss();
      })
      .catch((err) => {
        this.loader.dismiss();
      });
  }

  async downloadPictures() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Downloading...',
    });
    this.loader.present();
    // ionic upgrade commit
    // check if there is internet connection.
    const ping = await this.networkService.pingServer();
    if (ping) {
      this.attachmentService
        .downloadAttachments(this.noteId)
        .then(
          async (attachmentsResult: Array<any>) =>
            await this._onDownloadAttachments(attachmentsResult)
        );
    } else {
      this.loader.dismiss();
      const message = await this.alert.create({
        header: '',
        message:
          'Looks like server is not responding, please check if there is internet connection',
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await message.present();
      await message.onDidDismiss();
    }
  }

  private async _onDownloadAttachments(attachmentsResult: Array<any>) {
    const attachmentRepository = db.attachment;
    this.attachments = [];
    for (const at of attachmentsResult) {
      const attachment = await attachmentRepository
        .where({ Id: at['id'] })
        .first();
      const photo = `data:image/png;base64, ${at['fileArray']}`;     
        const contentType = this.getContentType(photo);
        const DataBlob = this.base64toBlob(photo, contentType);
        attachment['FormatedFile'] = `${photo}`;
        attachment['BlobFile'] = DataBlob;
        attachment.IsDownloaded = true;
        this.attachments.push(attachment as AttachmentDexie);
        db.attachment.put(attachment);
      
    }
    const OfflineAttachments = await db.attachment
      .where({ NoteId: this.noteId, Id: '' })
      .toArray();   
    this.attachments.push(...(OfflineAttachments as AttachmentDexie[]));
    this.loader.dismiss();
  }

  // here is the method is used to get content type of an bas64 data
  getContentType(base64Data: any) {
    const block = base64Data.split(';');
    const contentType = block[0].split(':')[1];
    return contentType;
  }

  private _getFileExt(fileType) {
    switch (fileType) {
      case 'image/jpeg':
        return '.jpg';
      case 'image/gif':
        return '.gif';
      case 'image/bmp':
        return '.bmp';
      case 'image/tiff':
        return '.tif';
      case 'image/png':
        return '.png';
      case 'application/msword':
        return '.doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      case 'text/plain':
        return '.txt';
      case 'application/vnd.ms-excel':
        return '.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'application/pdf':
        return '.pdf';
      case 'application/rtf':
        return '.rtf';
      default:
        return '';
    }
  }
  // here is the method is used to convert base64 data to blob data
  public base64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const data = b64Data.split(',')[1];
    const byteCharacters = atob(data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    });
    return blob;
  }

  blobToBase64(path, fileName) {
    return this.file
      .readAsDataURL(path, fileName)
      .then((base64Img) => {
        return base64Img;
      })
      .catch((err: TypeError) => {
        this.loader.dismiss();
        return '';
      });
  }
}
