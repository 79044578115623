import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { AppBaseEntity } from '../EntityIndex';
import { environment } from 'src/environments/environment';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';

@Injectable({
  providedIn: 'root',
})
export class OBSService {
  serviceName = 'Hierarchy';
  downloadOperationName = 'obs';
  currentVersion = '1.0';

  constructor(
    private readonly _http: HttpClient,
    private readonly _syncLogerEntityManager: SynclogEntityManagerService
  ) {}

  async getItems(
    eventId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<any[]> {
    let httpParams = new HttpParams();

    if (dateFrom) httpParams = httpParams.append('DateFrom', dateFrom);
    if (dateTo) httpParams = httpParams.append('DateTo', dateTo);
    httpParams = httpParams.append('api-version', this.currentVersion);
    try {
      const vals = await this._http
        .get<any[]>(
          `${environment.apiEndpoint}/${this.serviceName}/${this.downloadOperationName}`,
          {
            params: httpParams,
          }
        )
        .pipe(timeout(300000))
        .toPromise();
      const helperValues = this.getNewVals(vals);
      if (vals.length > 0) {
        this._syncLogerEntityManager.createUploadSyncLogForEvent(
          helperValues?.provider,
          -1,
          this.downloadOperationName,
          helperValues?.largestValue
        );
        return helperValues?.newVals;
      } else {
        return helperValues?.newVals;
      }
    } catch (error) {
      throw error;
    }
  }

  getIncrementalItems(provider: string): Promise<any[]> {
    return new Promise<AppBaseEntity[]>((resolve, reject) => {
      this._syncLogerEntityManager
        .getLastSyncLogsForEntitiesByEnent(
          provider,
          NaN,
          this.downloadOperationName
        )
        .then((log) => {
          const dateFrom = log ? log.LastDownloadTime : null;
          this.getItems(-1, dateFrom, null)
            .then((rets) => {
              resolve(rets);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNewVals(vals: any[]): {
    largestValue: number;
    provider: string;
    newVals: any[];
  } {
    const helperValues = {
      largestValue: NaN,
      provider: '',
      newVals: [],
    };
    helperValues.newVals = vals.map((val) => {
      const newVal = new AppBaseEntity(val);
      const ud = Date.parse(newVal.UpdatedDate);
      if (helperValues.provider === '') {
        helperValues.provider = newVal['Provider']
          ? newVal['Provider']
          : 'TaPro';
      }
      if (isNaN(helperValues.largestValue) || ud > helperValues.largestValue)
        helperValues.largestValue = ud;
      return newVal;
    });
    return helperValues;
  }
}
