// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taProBtn {
  position: absolute;
  --border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  opacity: 0.75;
  height: 25px;
  top: 6px;
  left: 80px;
  margin-left: 0;
}

@media (max-width: 576px) {
  .taProBtn {
    --border-radius: 0px 10px 10px 0px;
    border-radius: 0px 10px 10px 0px;
    top: 40px;
    left: 0px;
  }
}
.backBtn {
  stroke: white;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oCAAA;EACA,kCAAA;EACA,uCAAA;EACA,aAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;EACA,cAAA;AACF;;AAEA;EACE;IACE,kCAAA;IACA,gCAAA;IACA,SAAA;IACA,SAAA;EACF;AACF;AAEA;EACE,aAAA;AAAF","sourcesContent":[".taProBtn {\n  position: absolute;\n  --border-radius: 10px 10px 10px 10px;\n  border-radius: 10px 10px 10px 10px;\n  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;\n  opacity: 0.75;\n  height: 25px;\n  top: 6px;\n  left: 80px;\n  margin-left: 0;\n}\n\n@media (max-width: 576px) {\n  .taProBtn {\n    --border-radius: 0px 10px 10px 0px;\n    border-radius: 0px 10px 10px 0px;\n    top: 40px;\n    left: 0px;\n  }\n}\n\n.backBtn {\n  stroke: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
