import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { Platform } from '@ionic/angular';

@Injectable()
export class AppStorageProvider {
  constructor(
    //private secureStorage: SecureStorage,
    private platform: Platform
  ) {}

  private getKey(): string {
    return Constants.SecureStorageKey;
  }

  /**
   * Get the value from local storage using the key provided
   * @param key - Get the value for the key stored in storage
   * @param fromSecure - Optional parameter to find key in secure storage or not, default is false
   */
  GetLocal(key: string, fromSecure?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      (async () => {
        resolve(localStorage.getItem(key));
      })();
    });
  }

  /**
   * Set value to local storage using the key provided
   * @param key - Key used to store the value in storage
   * @param value - Value to be stored in the storage
   * @param toSecure - Optional parameter to find store in secure storage or not, default is false
   */
  SetLocal(key: string, value: any, toSecure?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        localStorage.setItem(key, value);
        resolve(); // since no method is called inside it
      })();
    });
  }

  /**
   * Clear Local Storage
   * @param clearSecure - Clear Securage storage or not.
   */
  Clear(clearSecure?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      (async () => {
        localStorage.clear();
      })();
    });
  }
}
