import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { ADALProvider } from 'src/shared/adal/adal';
import * as moment from 'moment';
import { SyncLogDexie } from '../entity/dexie/SyncLog.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class SynclogEntityManagerService extends AppBaseEntityManager {
  constructor(private adalService: ADALProvider) {
    super();
  }

  async getLastSyncLogsForEntitiesByEnent(
    provider: string,
    eventId: number,
    entity: string
  ): Promise<SyncLogDexie> {
    try {
      const synclog = await db.synclog
        .filter((s) => s.Provider === provider && s.Entity === entity)
        .offset(0)
        .limit(1)
        .toArray();
      if (eventId) {
        synclog.filter((s) => s.EventId == eventId).reverse();
      }
      if (synclog?.length > 0) {
        const log = new SyncLogDexie(synclog);
        return log;
      }
    } catch (e) {
      throw e;
    }
  }

  createUploadSyncLogForEvent(
    provider: string,
    eventId: number,
    serviceName: string,
    largestValue: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const syncLog = new SyncLogDexie();
      syncLog.Entity = serviceName;
      syncLog.EventId = eventId;
      syncLog.Provider = provider;
      syncLog.CreatedByUPN = this.adalService.getUPN();
      syncLog.CreatedDate = moment().toISOString();
      syncLog.LastDownloadTime = moment(
        new Date(largestValue + 1)
      ).toISOString();
      syncLog.LastUploadTime = '';
      db.synclog
        .put(syncLog)
        .then(() => {
          resolve();
        })
        .catch((err) => console.log(err));
    });
  }
}
