import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppBaseServiceProvider } from './AppBaseService';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { NoteEntityManagerService } from '../entityManager/note-entity-manager.service';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';
import { Platform } from '@ionic/angular';
import { NoteAttachmentEntityManagerService } from '../EntityManagerIndex';
import { AttachmentHelperService } from '../helper/attachment-helper.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends AppBaseServiceProvider {
  serviceName = 'Note';
  currentVersion = '1.0';

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    private platform: Platform,
    public adalService: ADALProvider,
    public noteManager: NoteEntityManagerService,
    private noteAttachmentEntityManager: NoteAttachmentEntityManagerService,
    private attachmentService: AttachmentHelperService
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }

  async uploadOfflineNotes(provider: string, eventId: number): Promise<void> {
    this.noteManager.getOfflineNotes(provider, eventId).then((notes) => {
      if (notes?.length > 0) {
        this.saveItems(notes)
          .then(async (_) => {
            const attachments =
              await this.noteAttachmentEntityManager.getAttachmentsForNotesHome(
                notes
              );
            for (let att of attachments) {
              if (att?.FileURI) {
                att.FormatedFile =
                  await this.attachmentService.getImageBlobFile(att);
              }
            }
            await this.attachmentService.saveAttachments(attachments);
          })
          .catch((err) => {
            throw err;
          });
      }
    });
  }
}
