import { AppBaseEntityD } from "../AppBaseEntityD";

export interface INoteComment {
    Id: string;
    NoteId: string;
    Type: string;
    Text: string;
    KazakText: string;
    RussianText: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class NoteCommentDexie extends AppBaseEntityD implements INoteComment {
    constructor(NoteCommentObject?: object) {
        super();
        for (const key in NoteCommentObject) {
          if (NoteCommentObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = NoteCommentObject[key];
          }
        }
      }
      Id: string;
      NoteId: string;
      Type: string;
      Text: string;
      KazakText: string;
      RussianText: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}