import { AppBaseEntityD } from "../AppBaseEntityD";
import { NoteDexie } from "./Note.dexie";

export interface IHierarchy {
    ForeignId: number;
    ForeignParentId: number;
    EventId: number;
    ForeignEntity: string;
    ForeignParentEntity: string;
    DisplayId: string;
    Name: string;
    Provider: string;
    Sequence: number;
    Category: string;
    IsCompleted: boolean;
    InstanceName: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;

  isExpanded: boolean;
  isChecked: boolean;
  parent: HierarchyDexie;
  children: HierarchyDexie[];
  notes: NoteDexie[];
  isMatched: boolean;
  activityChecked: boolean;
}

export class HierarchyDexie extends AppBaseEntityD implements IHierarchy {
    constructor(hierarchyObject?: object) {
        super();
        for (const key in hierarchyObject) {
          if (hierarchyObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = hierarchyObject[key];
          }
        }
      }
      ForeignId: number;
      ForeignParentId: number;
      EventId: number;
      ForeignEntity: string;
      ForeignParentEntity: string;
      DisplayId: string;
      Name: string;
      Provider: string;
      Sequence: number;
      Category: string;
      IsCompleted: boolean;
      InstanceName: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
  
    isExpanded: boolean;
    isChecked: boolean;
    parent: HierarchyDexie;
    children: HierarchyDexie[];
    notes: NoteDexie[];
    isMatched: boolean;
    activityChecked: boolean;
}