import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HierarchyCellComponent } from './hierarchy-cell/hierarchy-cell.component';
import { NoteDetailComponent } from './note-detail/note-detail.component';
import { HierarchyListWithnotesComponent } from './hierarchylist-withnotes/hierarchylist-withnotes.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LoadingComponent } from './loading/loading.component';
import { BlockUIModule } from 'primeng/blockui';
import { NoteListComponent } from './note-list/note-list.component';
import { FormsModule } from '@angular/forms';
import { BrandComponent } from './brand/brand.component';
import { NoNotesComponent } from './no-notes/no-notes.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { NoteListCellComponent } from './note-list-cell/note-list-cell.component';
import { EventFilterComponent } from './event-filter/event-filter.component';
import { QuillModule } from 'ngx-quill';
import { PhotoOnlyComponent } from './photo/photo-only';
import { ImageViewerComponent } from './image-viewer/image-viewer';
import { SharedModule } from 'src/shared/shared.module';
import { ButtonModule } from "primeng/button";
import { WebcamModule } from 'ngx-webcam';
@NgModule({
    declarations: [
        HierarchyCellComponent,
        HierarchyListWithnotesComponent,
        NoteDetailComponent,
        LoadingComponent,
        NoteListComponent,
        BrandComponent,
        NoNotesComponent,
        HierarchyComponent,
        NoteListCellComponent,
        EventFilterComponent,
        PhotoOnlyComponent,
        ImageViewerComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        SelectButtonModule,
        BlockUIModule,
        ButtonModule,
        QuillModule.forRoot(),
        FormsModule,
        SharedModule,
        WebcamModule
    ],
    exports: [
        NoteListComponent,
        HierarchyCellComponent,
        HierarchyListWithnotesComponent,
        NoteDetailComponent,
        SelectButtonModule,
        ButtonModule,
        LoadingComponent,
        BrandComponent,
        NoNotesComponent,
        HierarchyComponent,
        NoteListCellComponent,
        EventFilterComponent,
        PhotoOnlyComponent,
    ]
})
export class ComponentsModule { }
