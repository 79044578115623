import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from './AppBaseService';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';
import { HierarchyDexie } from '../entity/dexie/Hierarchy.dexie';

@Injectable({
  providedIn: 'root',
})
export class HierarchyService extends AppBaseServiceProvider {
  serviceName = 'Hierarchy';
  flattenedHierarchyArray: HierarchyDexie[];
  selectedHierarchyPath: HierarchyDexie[];
  currentVersion = '1.0';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }
}
