import { AppBaseEntityD } from "../AppBaseEntityD";

export interface ISyncLog {
    Id: number;
    Entity: string;
    EventId: number;
    Provider: string;
    LastUploadTime: string;
    LastDownloadTime: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class SyncLogDexie extends AppBaseEntityD implements ISyncLog {
    constructor(synclogObject?: object) {
        super();
        for (const key in synclogObject) {
          if (synclogObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = synclogObject[key];
          }
        }
      }
      Id: number;
      Entity: string;
      EventId: number;
      Provider: string;
      LastUploadTime: string;
      LastDownloadTime: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
}