import Dexie from 'dexie';
import { ICODE, CODEDexie } from '../data/entity/dexie/CODE.dexie';
import { IOBS , OBSDexie } from 'src/data/entity/dexie/OBS.dexie';
import { ISyncLog ,SyncLogDexie } from 'src/data/entity/dexie/SyncLog.dexie';
import { IEvent ,EventDexie } from 'src/data/entity/dexie/Event.dexie';
import { INoteComment, NoteCommentDexie } from 'src/data/entity/dexie/NoteComment.dexie';
import { ILessonLearnt, LessonLearntDexie } from 'src/data/entity/dexie/LeasonLearnt.dexie';
import { IAttachment, AttachmentDexie } from 'src/data/entity/dexie/Attachment.dexie';
import {INote, NoteDexie } from 'src/data/entity/dexie/Note.dexie';
import { IHierarchy, HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { INoteTag, NoteTagDexie } from 'src/data/entity/dexie/NoteTag.dexie';
import { IUser, UserDexie } from 'src/data/entity/dexie/User.dexie';
import { ISummary, SummaryDexie } from 'src/data/entity/dexie/Summary.dexie';

export class AppDB extends Dexie{
  
    code!:Dexie.Table<ICODE,string>;
    obs!:Dexie.Table<IOBS, string>;
    synclog!:Dexie.Table<ISyncLog, number>
    event!:Dexie.Table<IEvent, number>
    notecomment!:Dexie.Table<INoteComment, string>
    lessonlearnt!:Dexie.Table<ILessonLearnt, string>
    attachment!:Dexie.Table<IAttachment, string>
    notes!:Dexie.Table<INote,string>
    hierarchy!:Dexie.Table<IHierarchy, number>
    notetag!:Dexie.Table<INoteTag, string>
    user!:Dexie.Table<IUser, string>
    summary!:Dexie.Table<ISummary, string>

    constructor(){
        super('ToNotes-db');
        this.version(1).stores({
            code: 'Value, Category',
            obs: 'Name, InstanceId',
            synclog: '++Id',
            event: '++ForeignId, ForeignParentEntity',
            notecomment: '++Id, NoteId',
            lessonlearnt: '++Id, NoteId',
            attachment: '++IdRow, Id, NoteId,TimeStamp',
            notes: '++Id,EventId,CreatedByUPN,UpdatedByUPN,UpdatedDate,CreatedDate,ShiftName,ShiftDate,Role,ParentId,IsUploaded',
            hierarchy:'++ForeignId, Category, UpdatedDate, Name',
            notetag:'NoteId,Tag',
            user:'Upn',
            summary:'Id'
        });

        
          
       
        // The following allows us to pull the records out of the DBs as the classes we defined e.g if we perform a .where on user, it will return objects of the User class
        this.code.mapToClass(CODEDexie);
        this.obs.mapToClass(OBSDexie);
        this.synclog.mapToClass(SyncLogDexie);
        this.event.mapToClass(EventDexie);
        this.notecomment.mapToClass(NoteCommentDexie);
        this.lessonlearnt.mapToClass(LessonLearntDexie);
        this.attachment.mapToClass(AttachmentDexie);
        this.notes.mapToClass(NoteDexie);
        this.hierarchy.mapToClass(HierarchyDexie);
        this.notetag.mapToClass(NoteTagDexie);
        this.user.mapToClass(UserDexie);
        this.summary.mapToClass(SummaryDexie);
    }

      // function to clear local db (for debugging / testing purposes)
  // returns success
  public async clearDataFromTables() {
    await db.transaction('rw', db.tables, async () => {
      // Iterate over each table in the database
      for (const table of db.tables) {
        await table.clear(); // Clear all data from the table
      }
    });
  }
}

export const db = new AppDB();
