import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';
import { ProfileHelperService } from 'src/data/helper/profile-helper.service';
import { catchError, finalize, map } from 'rxjs/operators';


export const profileGuard = (): Observable<boolean> => {
  const loadingService = inject(LoadingService);
  loadingService.present();
  return from(inject(ProfileHelperService).getProfile().then(profile => {
    if(profile){
      loadingService.dismiss();
      return true
    }else{
      return false;
    }
  }).catch(error => {
    loadingService.dismiss();
    return true;
  })
  );
};

