import { Column, BaseEntity } from 'typeorm/browser';
import { ColumnMetadata } from 'typeorm/browser/metadata/ColumnMetadata';

export class AppBaseEntity extends BaseEntity {
  constructor(obj?: object) {
    super();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        this[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
      }
    }
  }
  
  @Column({ nullable: true, type: 'text' })
  UpdatedDate: string;

  @Column({ nullable: true, type: 'text' })
  UpdatedByUPN: string;

  @Column({ nullable: true, type: 'text' })
  CreatedByUPN: string;

  @Column({ nullable: true, type: 'text' })
  CreatedDate: string;

  @Column({ nullable: true, default: false, type: 'boolean' })
  IsDeleted: boolean;

  getInsertSqlForEntity(): string {
    let sql = '';
    let columns = '';
    let values = '';

    Object.keys(this).forEach(key => {
      if (this.hasOwnProperty(key)) {
        const column = key;
        if (columns !== '') {
          columns += ', ';
        }
        columns += column;

        let value = this[key];
        if (typeof value === 'string') {
          // tslint:disable-next-line
          value = value.replace(/'/g, "''");
          // tslint:disable-next-line
          value = "'" + value + "'";
        } else if (typeof value === 'boolean') {
          value = value === true ? 1 : 0;
        } else if (value === undefined || value === null) {
          value = null;
        }

        if (values !== '') {
          values += ', ';
        }
        values += value;
      }
    });

    sql = `replace into ${this.constructor.name} (${columns}) values (${values});`;
    return sql;
  }

  getSqlValues(entities: AppBaseEntity[]): string {
    let valuesForEntities = '';
    entities.forEach(entity => {
      let valuesForEntity = '(';
      Object.keys(entity).forEach(key => {
        let value = entity[key];
        if (value instanceof Array) {
          return;
        }

        if (typeof value === 'string') {
          // tslint:disable-next-line
          value = value.replace(/'/g, "''");
          // tslint:disable-next-line
          value = "'" + value + "'";
        } else if (typeof value === 'boolean') {
          value = value === true ? 1 : 0;
        } else if (value === undefined || value === null) {
          value = null;
        }

        if (valuesForEntity !== '(') {
          valuesForEntity += ', ';
        }

        valuesForEntity += value;
      });

      valuesForEntity += ')';

      if (valuesForEntities !== '') {
        valuesForEntities += ', ';
      }
      valuesForEntities += valuesForEntity;
    });
    return valuesForEntities;
  }

  getInsertSqlForEntities(entities: AppBaseEntity[]): string {
    let sql = '';
    let columns = '';
    let values = '';

    Object.keys(this).forEach(key => {
      if (this.hasOwnProperty(key)) {
        if (this[key] instanceof Array) {
          return;
        }
        const column = key;
        if (columns !== '') {
          columns += ', ';
        }
        columns += column;
      }
    });

    values = this.getSqlValues(entities);
    sql = `replace into ${this.constructor.name} (${columns}) values ${values};`;
    return sql;
  }
  
  static createFromObject(obj: any, entity: any, columnNames: ColumnMetadata[]){
    const helper = {}
    for (const key in obj) {
      const keyFormatted = key.charAt(0).toUpperCase() + key.slice(1);
      if (obj.hasOwnProperty(key) && columnNames.find(e => `${e.propertyName}` === `${keyFormatted}`)) {
        helper[keyFormatted] = obj[key];
      } 
      if( helper[keyFormatted] === undefined){
        delete helper[keyFormatted]
      }
    }
    const createdEntity = entity?.create({...helper});
    return createdEntity;
  }
}
