import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { ProfileHelperService } from 'src/data/helper/profile-helper.service';

@Component({
  selector: 'app-no-notes',
  templateUrl: './no-notes.component.html',
  styleUrls: ['./no-notes.component.scss'],
})
export class NoNotesComponent implements OnInit {
  @Input() hierarchy: HierarchyDexie;
  @Output()
  addNote: EventEmitter<HierarchyDexie | null> =
    new EventEmitter<HierarchyDexie | null>();
  isCompleted = false;

  get displayMessage(): string {
    const message = this.translate.instant('SHARED.NoTurnover');
    return this.hierarchy && this.hierarchy.Name
      ? message +
          this.translate.instant('SHARED.For') +
          this.hierarchy.Name +
          this.translate.instant('SHARED.MayAdd')
      : message;
  }
  constructor(
    private translate: TranslateService,
    private profileService: ProfileHelperService
  ) {}

  ngOnInit() {
    this.isCompleted = this.profileService.profile.isCompleted;
  }
}
