import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { db } from 'src/services/dexie.service';
import { NoteDexie } from '../entity/dexie/Note.dexie';
import { AttachmentDexie } from '../entity/dexie/Attachment.dexie';

@Injectable({
  providedIn: 'root',
})
export class NoteAttachmentEntityManagerService extends AppBaseEntityManager {
  protected _entityType = AttachmentDexie;
  dexieName = 'attachment';

  constructor() {
    super();
  }

  async getAttachmentsForNote(noteId: string): Promise<AttachmentDexie[]> {
    try {
      const attach = await db.attachment
        .filter((e) => e.NoteId === noteId)
        .and((e) => e.IsDeleted === false)
        .toArray();
      return attach as AttachmentDexie[];
    } catch (error) {
      throw error;
    }
  }

  async getAttachmentsForNotes(notes: NoteDexie[]): Promise<AttachmentDexie[]> {
    try {
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const attach = await db.attachment
        .where('NoteId')
        .anyOf(notesIds)
        .and((e) => e.IsDeleted === false)
        .toArray();        
      return attach as AttachmentDexie[];
    } catch (error) {
      throw error;
    }
  }

  async getAttachmentsForNotesHome(
    notes: NoteDexie[]
  ): Promise<AttachmentDexie[]> {
    try {
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const attach = await db.attachment
        .where('NoteId')
        .anyOf(notesIds)
        .toArray();
      return attach as AttachmentDexie[];
    } catch (error) {
      throw error;
    }
  }

  saveAttachments(attachments: AttachmentDexie[]): Promise<void> {
    const attachmentToSave: AttachmentDexie[] = attachments.map(
      (att: AttachmentDexie) =>
        ({
          Id: att.Id,
          NoteId: att.NoteId,
          FileName: att.FileName,
          IsDownloaded: att.IsDownloaded,
          IsUploaded: att.IsUploaded,
          TimeStamp: att.TimeStamp,
          Format: att.Format,
          FileURI: att.FileURI,
          UpdatedDate: att.UpdatedDate,
          UpdatedByUPN: att.UpdatedByUPN,
          CreatedByUPN: att.CreatedByUPN,
          CreatedDate: att.CreatedDate,
          IsDeleted: att.IsDeleted,
        } as AttachmentDexie)
    );
    return this.bulkInsert2(attachmentToSave, this.dexieName);
  }

  async deleteAttachmentsForNote(noteId: string): Promise<void> {
    try {
      await db.attachment.where('NoteId').equals(noteId).delete();
    } catch (error) {
      throw error;
    }
  }

  async deleteAttachmentsForNotes(noteIds: string[]): Promise<void> {
    try {
      await db.attachment.where('NoteId').anyOf(noteIds).delete();
    } catch (error) {
      throw error;
    }
  }

  async deleteAttachmentsForNotesNoLongerAvailable(
    noteIds: string[]
  ): Promise<void> {
    try {
      const notes = noteIds.map((noteId) => "'" + noteId + "'");
      await db.attachment
        .filter(
          (e) =>
            e.IsDeleted == true && e.IsUploaded == true && e.NoteId in notes
        )
        .delete();
    } catch (error) {
      throw error;
    }
  }
}
