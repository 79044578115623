import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NoteEntityManagerService } from 'src/data/entityManager/note-entity-manager.service';
import { NoteFilterAuthorType } from 'src/data/InternalTypes';
import { ADALProvider } from 'src/shared/adal/adal';
import { NoteCommentEntityManagerService } from 'src/data/entityManager/note-comment-entity-manager.service';
import { NoteLessonlearntEntityManagerService } from 'src/data/entityManager/note-lessonlearnt-entity-manager.service';
import { NoteTagEntityManagerService } from 'src/data/entityManager/note-tag-entity-manager.service';
import { Constants } from '../../shared/constants';
import { LoadingService } from 'src/services/loading.service';
import { NoteAttachmentEntityManagerService } from 'src/data/EntityManagerIndex';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { NoteDexie } from 'src/data/entity/dexie/Note.dexie';

@Component({
  selector: 'app-hierarchylist-withnotes',
  templateUrl: './hierarchylist-withnotes.component.html',
  styleUrls: ['./hierarchylist-withnotes.component.scss'],
})
export class HierarchyListWithnotesComponent implements OnInit {
  constructor(
    private noteEntityManager: NoteEntityManagerService,
    private noteCommentEntityManager: NoteCommentEntityManagerService,
    private noteLessonLearntEntityManager: NoteLessonlearntEntityManagerService,
    private noteTagEntityManager: NoteTagEntityManagerService,
    private adalService: ADALProvider,
    private loagingService: LoadingService,
    private noteAttachmentEntityManager: NoteAttachmentEntityManagerService
  ) {}

  @Input() hierarchy: HierarchyDexie;
  @Input() noteAuthor: NoteFilterAuthorType;
  @Input() showEvenIfNoNotes = false;
  @Input() isCompleted = false;
  @Input() isListItemOpened: boolean;
  @Output() addNote: EventEmitter<HierarchyDexie> =
    new EventEmitter<HierarchyDexie>();
  notes: NoteDexie[];
  sliceNotes: NoteDexie[];
  firstNote: NoteDexie;

  notesCount: number;
  offset: number;
  showAddMoreButton: boolean;
  // tslint:disable-next-line: no-inferrable-types
  @Input() expand: boolean = false;
  @Input() collapse = true;

  ngOnInit() {
    this.offset = 0;
    if (
      this.hierarchy &&
      this.hierarchy.notes &&
      this.hierarchy.notes.length > 0
    ) {
      this.notes = this.hierarchy.notes;
      this.notesCount = this.notes.length;
      if (this.notesCount > Constants.pagingSize) {
        this.showAddMoreButton = true;
      }
      this.getNotesCommentAndTagFirst();
    } else {
      this.getNotesForHierarchy().then((res) => {
        this.notes = res;
        this.notesCount = this.notes.length;
        if (this.notesCount > Constants.pagingSize) {
          this.showAddMoreButton = true;
        }
        this.getNotesCommentAndTagFirst();
      });
    }
  }

  async viewNotes() {
    this.loagingService.present();
    if (this.expand === false) {
      await this.getNotesCommentAndTag();
    }
    this.loagingService.dismiss();
    this.expand = true;
    this.collapse = false;
  }

  async hideNotes() {
    await this.hideNotesCommentAndTag();
    this.expand = false;
    this.collapse = true;
  }

  async showMore() {
    this.sliceNotes = this.sliceNotes.concat(
      this.notes.slice(this.offset, Constants.pagingSize + this.offset)
    );
    this.offset = this.offset + Constants.pagingSize;
    if (this.notesCount <= this.offset) {
      this.showAddMoreButton = false;
    }
  }

  async getNotesCommentAndTagFirst() {
    if (this.sliceNotes === undefined) {
      if (this.notes !== undefined) {
        // just one query to get all comment
        const comments =
          await this.noteCommentEntityManager.getCommentsForNotes(this.notes);
        // just one query to get all lesson Learnts
        const lessonlearnts =
          await this.noteLessonLearntEntityManager.getLessonLearntForNotes(
            this.notes
          );
        // just one query to get all tags
        const tags = await this.noteTagEntityManager.getTagsForNotes(
          this.notes
        );
        // just one query to get all attachments
        const attachments =
          await this.noteAttachmentEntityManager.getAttachmentsForNotes(
            this.notes
          );
        for (const note of this.notes) {
          note.Comments = comments.filter(
            (comment) => comment.NoteId === note.Id
          );
          note.Lessonlearnt = lessonlearnts.filter(
            (lessonlearnt) => lessonlearnt.NoteId === note.Id
          );
          note.Tags = tags
            .filter((tag) => tag.NoteId === note.Id)
            .map((tag) => tag.Tag);
          note.Attachments = attachments.filter(
            (attachment) => attachment.NoteId.toString() === note.Id
          );
        }
        this.firstNote = this.notes[0];
        this.offset = this.offset + 1;
      }
    }
  }

  async getNotesCommentAndTag() {
    if (this.notes !== undefined) {
      if (this.sliceNotes === undefined) {
        // just one query to get all comment
        const comments =
          await this.noteCommentEntityManager.getCommentsForNotes(this.notes);
        // just one query to get all lesson learnt
        const lessonlearnts =
          await this.noteLessonLearntEntityManager.getLessonLearntForNotes(
            this.notes
          );
        // just one query to get all tags
        const tags = await this.noteTagEntityManager.getTagsForNotes(
          this.notes
        );
        // just one query to get all attachments
        const attachments =
          await this.noteAttachmentEntityManager.getAttachmentsForNotes(
            this.notes
          );
        for (const note of this.notes) {
          note.Comments = comments.filter(
            (comment) => comment.NoteId === note.Id
          );
          note.Lessonlearnt = lessonlearnts.filter(
            (lessonlearnt) => lessonlearnt.NoteId === note.Id
          );
          note.Tags = tags
            .filter((tag) => tag.NoteId === note.Id)
            .map((tag) => tag.Tag);
          note.Attachments = attachments.filter(
            (attachment) => attachment.NoteId.toString() === note.Id
          );
        }
      }
      this.sliceNotes = this.notes.slice(this.offset, Constants.pagingSize);
      this.offset = this.offset + Constants.pagingSize;
    }
  }

  async hideNotesCommentAndTag() {
    this.sliceNotes = [];
    this.offset = 1;
  }

  async getNotesForHierarchy(): Promise<NoteDexie[]> {
    try {
      const user =
        this.noteAuthor === NoteFilterAuthorType.me
          ? this.adalService.getUPN()
          : null;
    const res =  await this.noteEntityManager
        .getNotesForHierarchy(this.hierarchy, user);
        return res;
    } catch(error){
      throw error;
    }

}

  addNotes() {
    this.addNote.emit(this.hierarchy);
  }
}
