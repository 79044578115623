import { AppBaseEntityD } from "../AppBaseEntityD";

export interface IAttachment {
    IdRow: string;
    Id: string;
    NoteId: string;
    FileName: string;
    IsDownloaded: boolean;
    IsUploaded: boolean;
    TimeStamp: number;
    Format: string;
    FileURI: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;

    FormatedFile: any;
    BlobFile: any;
    FileArray: any;
}

export class AttachmentDexie extends AppBaseEntityD implements IAttachment {
    constructor(AttachmentObject?: object) {
        super();
        for (const key in AttachmentObject) {
          if (AttachmentObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = AttachmentObject[key];
          }
        }
      }
      IdRow: string;
      Id: string;
      NoteId: string;
      FileName: string;
      IsDownloaded: boolean;
      IsUploaded: boolean;
      TimeStamp: number;
      Format: string;
      FileURI: string;
      UpdatedDate: string;
      UpdatedByUPN: string;
      CreatedByUPN: string;
      CreatedDate: string;
      IsDeleted: boolean;
  
      FormatedFile: any;
      BlobFile: any;
      FileArray: any;
}