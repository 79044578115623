import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: "root"})
export class EventService {
    private formRefreshAnnouncedSource = new Subject<string>();
    formRefreshSource$ = this.formRefreshAnnouncedSource.asObservable();
  
    publishFormRefresh(style: string, url: any ){
      this.formRefreshAnnouncedSource.next(url);
    }
}