import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserHelperService } from 'src/data/helper/user-helper.service';
import { Observable, from } from 'rxjs';
import { NoteDexie } from 'src/data/entity/dexie/Note.dexie';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { UserDexie } from 'src/data/entity/dexie/User.dexie';

@Component({
  selector: 'app-note-detail',
  templateUrl: './note-detail.component.html',
  styleUrls: ['./note-detail.component.scss'],
})
export class NoteDetailComponent implements OnInit {
  @Input() note: NoteDexie;
  @Input() hierarchy: HierarchyDexie;
  user$: Observable<UserDexie>;

  action: string;
  constructor(private router: Router, private userHelper: UserHelperService) {}

  ngOnInit() {
    this.action = localStorage.getItem('action');
    this.user$ = from(this.userHelper.getUserInfo(this.note.CreatedByUPN));
  }

  navigateToNoteDetails(note: NoteDexie) {
    this.router.navigate([
      'note-details',
      this.hierarchy.Provider,
      note.EventId,
      note.Id,
    ]);
  }

  getUser(note: NoteDexie): Observable<UserDexie> {
    return from(this.userHelper.getUserInfo(note.CreatedByUPN));
  }

  getNoteFirstCommentForType(note: NoteDexie, commentType: string): string {
    let commentText = '';
    for (const comment of note.Comments) {
      const language = localStorage.getItem('language');
      if (comment.Type === commentType) {
        commentText = comment.Text;
        // if (language === 'kaz') {
        //   commentText = comment.KazakText;
        // }
        // if (language === 'ru') {
        //   commentText = comment.RussianText;
        // }
        break;
      }
    }
    return commentText;
  }
}
