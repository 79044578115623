import { Component, OnInit, Input } from '@angular/core';
import { UserHelperService } from 'src/data/helper/user-helper.service';
import { Observable, from } from 'rxjs';
import { UserDexie } from 'src/data/entity/dexie/User.dexie';
import { NoteDexie } from 'src/data/entity/dexie/Note.dexie';

@Component({
  selector: 'app-note-list-cell',
  templateUrl: './note-list-cell.component.html',
  styleUrls: ['./note-list-cell.component.scss'],
})
export class NoteListCellComponent implements OnInit {
  @Input() note: NoteDexie;
  user$: Observable<UserDexie>;
  constructor(private userHelper: UserHelperService) {}

  ngOnInit() {
    this.user$ = from(this.userHelper.getUserInfo(this.note.CreatedByUPN));
  }
}
