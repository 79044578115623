export class Profile {
  upn: string;
  isAdmin: boolean;
  preferredSOR: string;
  preferredOBS: string;
  preferredEvent: string;
  preferredEventDisplayId: string;
  preferredEventId: number;
  preferredRole: string;
  preferredEquipments: string;
  preferredWBSId: string;
  createdByUPN: string;
  createdDate: string;
  updatedByUPN: string;
  updatedDate: string;
  isCompleted: boolean;
}
