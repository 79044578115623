// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  height: 100%;
  background-color: #ececec;
}

.scroll-content {
  padding: 0 !important;
  margin: 16px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.18);
}

.main {
  overflow-y: auto;
  min-height: 55%;
  background-color: white;
}
.main ion-row {
  height: 100%;
}
.main div {
  max-width: 400px;
}

p {
  line-height: 1.5;
}
p[color=primary] {
  font-size: 24px;
}

.button {
  --border-radius: 0;
  font-weight: 700;
}

.col {
  padding: 0;
}
.col:first-child {
  padding-right: 8px;
}
.col:last-child {
  padding-left: 8px;
}

div.logo {
  height: 25%;
  position: relative;
  background-color: white;
}
div.logo div[padding] {
  position: absolute;
  width: 100%;
  bottom: 0;
}

div.buttons {
  position: relative;
  background-color: white;
}
div.buttons div[padding] {
  position: absolute;
  width: 100%;
  top: 0;
}
div.buttons div[padding] ion-grid {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

img {
  display: block;
  height: 100px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/notice/notice.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;EACA,0CAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;AAGE;EACE,eAAA;AADJ;;AAKA;EACE,kBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;;AAOA;EACE,WAAA;EACA,kBAAA;EACA,uBAAA;AAJF;AAME;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAJJ;;AAQA;EACE,kBAAA;EACA,uBAAA;AALF;AAOE;EACE,kBAAA;EACA,WAAA;EACA,MAAA;AALJ;AAOI;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AALN;;AAUA;EACE,cAAA;EACA,aAAA;EACA,cAAA;AAPF","sourcesContent":[".content {\n  height: 100%;\n  background-color: #ececec;\n}\n\n.scroll-content {\n  padding: 0 !important;\n  margin: 16px;\n  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.18);\n}\n\n.main {\n  overflow-y: auto;\n  min-height: 55%;\n  background-color: white;\n\n  & ion-row {\n    height: 100%;\n  }\n\n  div {\n    max-width: 400px;\n  }\n}\n\np {\n  line-height: 1.5;\n\n  &[color='primary'] {\n    font-size: 24px;\n  }\n}\n\n.button {\n  --border-radius: 0;\n  font-weight: 700;\n}\n\n.col {\n  padding: 0;\n\n  &:first-child {\n    padding-right: 8px;\n  }\n\n  &:last-child {\n    padding-left: 8px;\n  }\n}\n\ndiv.logo {\n  height: 25%;\n  position: relative;\n  background-color: white;\n\n  div[padding] {\n    position: absolute;\n    width: 100%;\n    bottom: 0;\n  }\n}\n\ndiv.buttons {\n  position: relative;\n  background-color: white;\n\n  div[padding] {\n    position: absolute;\n    width: 100%;\n    top: 0;\n\n    ion-grid {\n      width: 100%;\n      max-width: 400px;\n      margin: 0 auto;\n    }\n  }\n}\n\nimg {\n  display: block;\n  height: 100px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
