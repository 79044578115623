import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { NoteTagDexie } from '../entity/dexie/NoteTag.dexie';
import { db } from 'src/services/dexie.service';
import { NoteDexie } from '../entity/dexie/Note.dexie';

@Injectable({
  providedIn: 'root',
})
export class NoteTagEntityManagerService extends AppBaseEntityManager {
  protected _entityType = NoteTagDexie;
  dexieName = 'notetag';
  constructor() {
    super();
  }

  async getTagsForNote(noteId: string): Promise<string[]> {
    try {
      const noteComments = await db.notetag
        .filter((e) => e.NoteId === noteId)
        .toArray();
      const ret = noteComments.map((comment: NoteTagDexie) => comment.Tag);
      return ret;
    } catch (error) {
      throw error;
    }
  }

  async getTagsForNotes(notes: NoteDexie[]): Promise<NoteTagDexie[]> {
    try {
      const notesIds = notes.map(({ Id }) => Id);
      const tag = await db.notetag.where('NoteId').anyOf(notesIds).toArray();
      return tag as NoteTagDexie[];
    } catch (error) {
      throw error;
    }
  }

  saveTags(tags: NoteTagDexie[]): Promise<void> {
    return this.bulkInsert2(tags, this.dexieName);
  }

  async deleteTagsForNote(noteId: string): Promise<void> {
    try {
      await db.notetag.where('NoteId').equals(noteId).delete();
    } catch (error) {
      throw error;
    }
  }

  async deleteTagsForNotes(noteIds: string[]): Promise<void> {
    try {
      await db.notetag.where('NoteId').anyOf(noteIds).delete();
    } catch (error) {
      throw error;
    }
  }
}
