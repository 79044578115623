import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Connection, getConnection, QueryRunner } from 'typeorm/browser';
import { LoadingController, ToastController } from '@ionic/angular';
import { AuthService } from '../auth.service';
import { AppBaseServiceProvider } from 'src/data/entityService/AppBaseService';
import { AppError } from 'src/data/AppError';
import { AppErrorType } from 'src/data/InternalTypes';
import { SyncConfig } from 'src/interface/SyncConfig';
import {
  HierarchyService,
  NotesService,
  UserService,
  SummaryService,
} from 'src/data/EntityServiceIndex';
import { NoteEntityManagerService } from 'src/data/entityManager/note-entity-manager.service';
import { HierarchyEntityManagerService } from 'src/data/entityManager/hierarchy-entity-manager.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { UserEntityManagerService } from 'src/data/EntityManagerIndex';
import { CodeService } from 'src/data/entityService/code.service';
import { OBSService } from 'src/data/entityService/obs.service';
import { CodeEntityManagerService } from 'src/data/entityManager/code-entity-manager.service';
import { ObsEntityManagerService } from 'src/data/entityManager/obs-entity-manager.service';
import { NetworkService } from './../../services/network.service';
import { SummaryEntityManagerService } from 'src/data/entityManager/summary-entity-manager.service';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { LoadingService } from '../loading.service';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  projectId: number;
  lastUploadTime: string;
  lastDownloadTime: string;
  isFullSync = false;
  private connection: Connection;
  private queryRunner: QueryRunner;
  private syncProgress: number;
  totalSyncOperation: number;
  syncProgressStep: number;
  phase: 'Uploading' | 'Downloading';
  loading: HTMLIonLoadingElement;
  toast: HTMLIonToastElement;  
  loadingElement: any;
  constructor(
    public http: HttpClient,
    public adalService: ADALProvider,
    private authService: AuthService,
    private network: NetworkService,
    private loadingController: LoadingController,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private hierarchyService: HierarchyService,
    private noteService: NotesService,
    private noteEntityManager: NoteEntityManagerService,
    private hierarchyEntityManager: HierarchyEntityManagerService,
    private userService: UserService,
    private codeService: CodeService,
    private codeEntityManager: CodeEntityManagerService,
    private obsEntityManager: ObsEntityManagerService,
    private readonly _obsService: OBSService,
    private userEntityManager: UserEntityManagerService,
    private networkService: NetworkService,
    private summaryService: SummaryService,
    private summaryEntityManager: SummaryEntityManagerService
  ) {}
  entityServices: AppBaseServiceProvider[] = [];

  sync(projectId?: number): Promise<any> {
    this.projectId = projectId;
    return new Promise<void>((resolve, reject) => {
      (async () => {
        this.loading = await this.loadingController.create({
          spinner: 'crescent',
          message: 'Sync In Progress...',
        });
        await this.loading.present();
        this.enableBackgroundMode();

        this.fire()
          .then(() => {
            return this.prepare();
          })
          .then(() => {
            this.initSyncProgressProperty();
            return this.processData();
          })
          .then(() => {
            return this.complete();
          })
          .then(() => {
            this.completeProgress();
            this.loading.dismiss();
            resolve();
          })
          .catch(async (err) => {
            this.disableBackgroundMode();
            if (this.queryRunner) {
              await this.queryRunner.rollbackTransaction();
              await this.queryRunner.release();
            }

            const newErr =
              err instanceof AppError
                ? err
                : new AppError(AppErrorType.COMMON_SYNC_ERROR, err);
            if (newErr.type !== AppErrorType.MULTIPLE_PROJECTS_ASSIGNED) {
              this.failedProgress(newErr);
            }
            this.loading.dismiss();
            reject(newErr);
          });
      })();
    });
  }

  async fire(): Promise<void> {
    this.enableBackgroundMode();
    try {
      const usr = await this.authService.loginWithAD();
    } catch (err) {
      if (err && err.status === 403) {
        throw new AppError(
          AppErrorType.NOT_AUTHORIZED_USER,
          'You are not an authorized user. Please contact system admin.',
          err
        );
      } else {
        throw new AppError(
          AppErrorType.AUTHENTICATION_ERROR,
          'Authentication error. Please try it later.',
          err
        );
      }
    }
  }

  async prepare(): Promise<any> {
    // TODO: loading service
    try {
      this.connection = await getConnection();
      this.queryRunner = this.connection.createQueryRunner();
      this.queryRunner.startTransaction().then();
    } catch (error) {
      throw error;
    }
  }

  complete(): Promise<void> {
    return new Promise((resolve, reject) => {
      // TODO:
      resolve(); // since no method is called inside it
    });
  }

  async processData(): Promise<void> {
    try {
      this.phase = 'Uploading';
      for (const entityService of this.entityServices) {
        this.updateProgress(entityService.serviceName);
        await this.doUpload(entityService);
      }
      this.phase = 'Downloading';
      for (const entityService of this.entityServices) {
        this.updateProgress(entityService.serviceName);
        await this.doDownload(entityService);
      }

      return; // since no method is called inside it
    } catch (e) {
      throw e;
    }
  }

  async doUpload(entityService: AppBaseServiceProvider): Promise<void> {
    try {
      if (entityService.isUploadable()) {
        await entityService.upload(await this.getSyncConfig());
        this.syncProgress += this.syncProgressStep;
      }
      return; // since no method is called inside it
    } catch (e) {
      throw new AppError(
        AppErrorType.COMMON_SYNC_ERROR,
        `upload service ${entityService.serviceName} error.`,
        e
      );
    }
  }

  async doDownload(entityService: AppBaseServiceProvider): Promise<void> {
    try {
      if (entityService.isDownloadable()) {
        const config = await this.getSyncConfig();
        entityService.download(config).then(
          async (res) => {
            await new entityService.entityManger().bulkInsertWithQueryRunner(
              res,
              this.queryRunner
            );
            this.syncProgress += this.syncProgressStep;
            console.log(this.syncProgress)
            return; // since no method is called inside it
          },
          (err) => {
            throw new AppError(
              AppErrorType.SERVICE_ERROR,
              `download service ${entityService.serviceName} error.`,
              err
            );
          }
        );
      } else {
        return; // since no method is called inside it
      }
    } catch (e) {
      throw new AppError(AppErrorType.COMMON_SYNC_ERROR, e);
    }
  }

  async getSyncConfig(): Promise<SyncConfig> {
    const config = new SyncConfig();
    config.isFullSync = this.isFullSync;
    config.lastDownloadTime = this.lastDownloadTime;
    config.lastUploadTime = this.lastUploadTime;
    config.project = this.projectId;    
    //config.cai = localStorage.getItem('CAI');    
    config.userId = parseInt(localStorage.getItem('UserId'), 10);
    return config;
  }

  enableBackgroundMode() {
    console.info('empty method');
  }
  disableBackgroundMode() {
    console.info('empty method');
  }
  initSyncProgressProperty() {
    this.totalSyncOperation = 0;
    this.syncProgress = 0;
    for (const entityService of this.entityServices) {
      if (entityService.isDownloadable()) {
        this.totalSyncOperation += 1;
      }
      if (entityService.isUploadable()) {
        this.totalSyncOperation += 1;
      }
    }
    this.syncProgressStep = Math.floor(100 / this.totalSyncOperation);
  }

  updateProgress(entity: string) {
    const msg = `${this.syncProgress}% completed - ${this.phase} ${entity} data ...`;
    this.showLoadingMsg(msg);
  }

  completeProgress() {
    const msg = `Sync completed.`;
    this.showLoadingMsg(msg);
    this.showToastMsg(msg);
  }

  async failedProgress(err: AppError) {
    const ping = await this.network.pingServer();
    const msg = `${this.phase} data failed at ${this.syncProgress}%.`;
    this.showLoadingMsg(msg);
    const toastMsg =
      ping === false
        ? 'No Network available, Please try again later.'
        : 'Error. Sync Failed with error - ' +
          JSON.stringify(err.params) +
          ' - Please retry. If problem persists, call helpdesk.';
    this.showToastMsg(toastMsg);
    // TODO: analytics
  }

  showLoadingMsg(msg: string) {
    if (this.loadingElement) {
      this.loadingElement.innerHTML = msg;
    }
  }

  async showToastMsg(msg: string) {
    this.toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      //showCloseButton: false ---ionic 5 is not supporting
    });
    await this.toast.present();
  }

  async syncDataForEvent(
    provider: string,
    eventId: number,
    isCompleted?: boolean
  ): Promise<boolean> {
    try {
      const ping = await this.networkService.pingServer();
      if (ping === false) {
        throw new AppError(AppErrorType.NETWORK_ERROR, 'Not Network');
      } else {
        this.loadingService.present();
        await this.uploadOfflineNotes(provider, eventId);
        await this.syncHierarchyForEvent(provider, eventId, isCompleted);
        await this.syncNotesForEvent(provider, eventId, false);
        await this.syncUser(provider, eventId);
        await this.syncCodes(provider, eventId);
        await this.syncOBS(provider, eventId);
        await this.syncSummmaryForEvent(provider, eventId);
        this.uploadOfflineSummary(provider, eventId);
        this.loadingService.dismiss();
        return true;
      }
    } catch (error) {
      return error;
    }
  }

  async syncHierarchyForEvent(
    provider: string,
    eventId: number,
    isCompleted?: boolean
  ): Promise<void> {
    try {
      this.hierarchyService.serviceName = 'Hierarchy';
      const complete = String(isCompleted);
      if (complete === 'true') {
        this.hierarchyService.serviceName = 'ArchivedHierarchy';
      }
      const hierarchies: HierarchyDexie[] =
        await this.hierarchyService.getIncrementalItems(provider, eventId);
      await this.hierarchyEntityManager.saveHierarchies(hierarchies);
      return;
    } catch (error) {
      throw error;
    }
  }

  async syncNotesForEvent(
    provider: string,
    eventId: number,
    isUploadRequired: boolean = true
  ): Promise<void> {
    try {
      if (isUploadRequired) {
        await this.uploadOfflineNotes(provider, eventId);
      }

      const notes = await this.noteService.getIncrementalItems(
        provider,
        eventId
      );
      await this.noteEntityManager.saveNotes(notes);
      return;
    } catch (error) {
      return error;
    }
  }

  async syncUser(provider: string, eventId: number): Promise<void> {
    try {
      const users = await this.userService.getIncrementalItems(
        provider,
        eventId
      );
      await this.userEntityManager.saveUsers(users);
      return;
    } catch (error) {
      throw error;
    }
  }

  syncCodes(provider: string, eventId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          const codes = await this.codeService.getIncrementalItems(
            provider,
            eventId
          );
          await this.codeEntityManager.saveCodes(codes);
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  syncOBS(provider: string, eventId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          const obss = await this._obsService.getIncrementalItems(provider);
          await this.obsEntityManager.saveObs(obss);
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  uploadOfflineNotes(provider: string, eventId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.noteService
        .uploadOfflineNotes(provider, eventId)
        .then((_) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  syncSummmaryForEvent(
    provider: string,
    eventId: number,
    isUploadRequired: boolean = true
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          if (isUploadRequired) {
            this.uploadOfflineSummary(provider, eventId);
          }

          const summary = await this.summaryService.getIncrementalItems(
            provider,
            eventId
          );
          await this.summaryEntityManager.saveSummary(summary);
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
    });
  }
  // this method will use in future if required.....
  uploadOfflineSummary(provider: string, eventId: number) {
    return new Promise<void>((resolve, reject) => {
      this.summaryService
        .uploadOfflineSummary(provider, eventId)
        .then((_) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
