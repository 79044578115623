import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { NoteDexie } from '../entity/dexie/Note.dexie';
import { LessonLearntDexie } from '../entity/dexie/LeasonLearnt.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class NoteLessonlearntEntityManagerService extends AppBaseEntityManager {
  protected _entityType = LessonLearntDexie;
  dexieName = 'lessonlearnt';
  constructor() {
    super();
  }

  async getLessonLearntForNote(noteId: string): Promise<LessonLearntDexie[]> {
    try {
      const LL = await db.lessonlearnt
        .filter((e) => e.NoteId === noteId)
        .toArray();
      return LL as LessonLearntDexie[];
    } catch (error) {
      throw error;
    }
  }

  async getLessonLearntForNotes(
    notes: NoteDexie[]
  ): Promise<LessonLearntDexie[]> {
    try {
      const notesIds = notes.map(({ Id }) => Id);
      const LL = await db.lessonlearnt
        .where('NoteId')
        .anyOf(notesIds)
        .toArray();
      return LL as LessonLearntDexie[];
    } catch (error) {
      throw error;
    }
  }

  saveLessonLearnt(lessonlearnt: LessonLearntDexie[]): Promise<void> {
    return this.bulkInsert2(lessonlearnt, this.dexieName);
  }

  async deletelessonlearntForNote(noteId: string): Promise<void> {
    try {
      await db.lessonlearnt.where('NoteId').equals(noteId).delete();
    } catch (error) {
      throw error;
    }
  }

  async deletelessonlearntForNotes(noteIds: string[]): Promise<void> {
    try {
      await db.lessonlearnt.where('NoteId').anyOf(noteIds).delete();
    } catch (error) {
      throw error;
    }
  }
}
