import { Component, Inject, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

import { AppPlatform } from 'src/data/InternalTypes';
import { LoadingService } from 'src/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckVersionUpdateService } from 'src/services/check-version-update.service';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AuthService } from 'src/services/auth.service';
import { MonitoringService } from 'src/pages/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private platform: Platform,
    private monitoringService : MonitoringService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translate: TranslateService,
    private update: CheckVersionUpdateService,
    private auth:AuthService,
  ) {this.update.checkForVersionUpdates();}


  ngOnInit(): void {
    this.initializeApp();
    this.register();
  }
  private readonly _destroying$ = new Subject<void>();

  register(){
   if(this._checkLoginValid()){
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.auth.loginWithAD();
        this._destroying$.next();
        this._destroying$.complete(); 
      });
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this._destroying$.next();
        this._destroying$.complete();
      })
    }
  }

  private _checkLoginValid(): boolean {
		// added to satisy edge case where:
		// there's a valid login, but the settingsservice user context disappeared.
		const accountsMsal = this.authService.instance.getAllAccounts();
    if(accountsMsal.length > 0){
      return false
    }
    return true;
  }


  initializeApp() {
    this.platform.ready().then(() => {
   
      this.translate.addLangs(['en', 'ru', 'kaz']);
      this.translate.setDefaultLang('en');

      const lang = localStorage.getItem('language');

      if (lang) {
        this.translate.use(lang);
      } else {
        this.translate.use('en');
      }
    });
  }

}
