import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Profile } from '../model/Profile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileServiceProvider {
  serviceName = 'Profile';
  downloadOperationName = 'current';

  uploadOperationName = 'profile';
  currentVersion = '1.0';

  constructor(private readonly _http: HttpClient) {}

  getProfile(): Promise<Profile> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<Profile>((resolve, reject) => {
      (async () => {
        this._http
          .get<Profile>(
            `${environment.apiEndpoint}/${this.serviceName}/${this.downloadOperationName}`,
            {
              params: httpParams,
            }
          )
          .pipe(timeout(30000))
          .subscribe(
            (val) => {
              resolve(val);
            },
            (error) => {
              reject(error);
            }
          );
      })();
    });
  }

  saveProfile(profile: Profile): Promise<Profile> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);
    return new Promise<Profile>((resolve, reject) => {
      (async () => {
        this._http
          .post<Profile>(
            `${environment.apiEndpoint}/${this.serviceName}`,
            profile,
            {
              params: httpParams,
            }
          )
          .pipe(timeout(30000))
          .subscribe(
            (vals) => {
              resolve(vals);
            },
            (error) => {
              reject(error);
            },
            () => {}
          );
      })();
    });
  }
}
