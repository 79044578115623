import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppBaseServiceProvider } from './AppBaseService';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';
import { SummaryEntityManagerService } from '../entityManager/summary-entity-manager.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryService extends AppBaseServiceProvider {
  serviceName = 'summary';
  currentVersion = '1.0';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider,
    public summaryManager: SummaryEntityManagerService
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }

  uploadOfflineSummary(provider: string, eventId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.summaryManager
        .getOfflineSummary(provider, eventId)
        .then((summary) => {
          if (summary && summary.length > 0) {
            this.saveSummaryItems(summary);
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
