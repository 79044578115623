import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/services/loading.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.page.html',
  styleUrls: ['./notice.page.scss']
})
export class NoticePage implements OnInit {
  constructor(
    private alertCtrl: AlertController,
    private modal: ModalController,
    private loadingService: LoadingService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.loadingService.dismissAll();
  }

  onAgreeTapped() {
    localStorage.setItem('isNoticeAccepted', 'true');
    this.modal.dismiss();
  }

  async onDontAgreeTapped() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('NOTICE.AccessDenied'),
      message: this.translate.instant('NOTICE.MustAgree'),
      buttons: [this.translate.instant('SHARED.Ok')]
    });
    alert.present();
  }
}
