
import { Component, EventEmitter } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent {

  public archive = [{ val: 'Archived projects', isChecked: false }];
  private filterChecked: EventEmitter< any >;

  constructor(private popoverCtrl: PopoverController, navParams: NavParams) {
    this.filterChecked = navParams.data.theEmitter;
    this.archive[0].isChecked = navParams.data.status;
  }

  applyFilter(value: boolean) {
    this.filterChecked.emit(value);
  }

  closePopover() {
    this.popoverCtrl.dismiss();
  }

}
