import { AppBaseEntity } from "../AppBaseEntity";
import { AppBaseEntityD } from "../AppBaseEntityD";
import { DexieBase } from "./DexieBase";

export interface ICODE{
    Category: string;
    Value: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}

export class CODEDexie extends AppBaseEntityD implements ICODE{
    constructor(codeObject?: object) {
        super();
        for (const key in codeObject) {
          if (codeObject.hasOwnProperty(key)) {
            this[key.charAt(0).toUpperCase() + key.slice(1)] = codeObject[key];
          }
        }
      }
    Category: string;
    Value: string;
    UpdatedDate: string;
    UpdatedByUPN: string;
    CreatedByUPN: string;
    CreatedDate: string;
    IsDeleted: boolean;
}