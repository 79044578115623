import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { NoteEntityManagerService } from 'src/data/entityManager/note-entity-manager.service';
import { Router } from '@angular/router';
import { UserHelperService } from 'src/data/helper/user-helper.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { NoteFilterAuthorType } from 'src/data/InternalTypes';
import { LoadingService } from 'src/services//loading.service';
import { HierarchyDexie } from 'src/data/entity/dexie/Hierarchy.dexie';
import { NoteDexie } from 'src/data/entity/dexie/Note.dexie';
import { UserDexie } from 'src/data/entity/dexie/User.dexie';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
})
export class NoteListComponent implements OnChanges {
  notes$: Observable<NoteDexie[]>;
  @Input() hierarchy$: Observable<HierarchyDexie>;
  hierarchy: HierarchyDexie;
  noteAuthor = NoteFilterAuthorType.other;
  NoteFilterAuthorType = NoteFilterAuthorType;

  user$: Observable<UserDexie>;

  isHierarchyObsSubscribed = false;

  constructor(
    private noteEntityManager: NoteEntityManagerService,
    private router: Router,
    private userHelper: UserHelperService,
    private adalService: ADALProvider,
    private loadingService: LoadingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'hierarchy$') {
        if (!this.isHierarchyObsSubscribed && this.hierarchy$) {
          this.isHierarchyObsSubscribed = true;
          this.hierarchy$.subscribe((ret) => {
            this.hierarchy = ret;
          });
        }
        const chng = changes[propName];
        this.hierarchy$ = of(chng.currentValue);
      }
    }
  }

  getUser(note: NoteDexie): Observable<UserDexie> {
    return from(this.userHelper.getUserInfo(note.CreatedByUPN));
  }

  getNoteForAuthor(noteAuthor: NoteFilterAuthorType) {
    let user = null;
    if (this.noteAuthor === NoteFilterAuthorType.me)
      user = this.adalService.getUPN();
    this.loadingService.present();
    this.notes$ = from(
      this.noteEntityManager.getNotesForHierarchy(this.hierarchy, user)
    );

    this.notes$.subscribe(() => {
      this.loadingService.dismiss();
    });
  }

  navigateToNoteDetails(note: NoteDexie) {
    this.router.navigate([
      'note-details',
      this.hierarchy.Provider,
      note.EventId,
      note.Id,
    ]);
  }
}
